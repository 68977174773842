<script>
    import { onMount, afterUpdate, createEventDispatcher } from 'svelte';

    import Calendar from 'js-year-calendar';
    import Legend from './Legend.svelte';
    import '../../../../node_modules/js-year-calendar/dist/js-year-calendar.css';

    export let events = [];
    export let absenceTypes = [];
    let calendar;
    let calendarInstance;

    const dispatch = createEventDispatcher();

    afterUpdate(() => {
        events.startDate = events.start;
        events.endDate = events.end;

        // FILTRAR EVENTOS CON visible = true
        events = events
        .filter(e => e.visible)
        .map(e => {
            e.startDate = e.start;
            e.endDate = e.end;
            return e;
        });
        calendarInstance.setDataSource(events);
    });

    onMount(() => {
        //const calendar = document.querySelector('#calendar')
        calendarInstance = new Calendar(calendar, {
            //dataSource: events,

            weekStart: 1,
            style: "custom",
            allowOverlap: false,
            enableContextMenu: true,
            enableRangeSelection: true,
            //displayDisabledDataSource: ¿? // Specifies whether the data source must be rendered on disabled days.
            disabledWeekDays: [6,0],
            contextMenuItems: [
                {
                    text: 'Update',
                    click: () => {}
                },
                {
                    text: 'Delete',
                    click: () => {}
                },
            ],

            customDataSourceRenderer: (element, currentDate, events) => {
                const color = events[0].colorLight;
                const current = currentDate.toLocaleDateString();
                const start = events[0].start.toLocaleDateString();
                const end = events[0].end.toLocaleDateString();
                
                if (current === start && current === end) {
                    element.style.borderRadius = '12px';
                } else if (current === start) {
                    element.style.borderRadius = '12px 0 0 12px';
                } else if (current === end) {
                    element.style.borderRadius = '0 12px 12px 0';
                } else {
                    element.style.borderRadius = '0';
                }

                element.style.backgroundColor = color;
                element.style.color = 'white';
                element.style.padding = '0';
            },

            clickDay: (e) => {
                dispatch('dayClicked', e);
            },
            mouseOnDay: () => {
                //console.log('mouseOnDay');
            },
            selectRange: (e) => {
                dispatch('rangeSelected', e);
            }
        });
    });
</script>

<div id="calendarContainer" class="flex flex-col">
    <div bind:this={calendar} class="noSelect"></div>
    <div id="legendContainer" class="flex">
        <Legend text='General Holidays' color='red' />
        {#each absenceTypes as { name, color }}
            <Legend text={name} {color} />
        {/each}
    </div>
</div>

<style>

    .flex {
        display: flex;
    }

    .flex-col {
        flex-direction: column;
    }

    #calendarContainer {
        width: 100%;
        height: 100%;
        align-items:safe;
        justify-content: space-around;
    }

    #legendContainer {
        width: 110%;
        justify-content: space-evenly;
        font-size: 12px;
    }

    .noSelect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
    }

</style>