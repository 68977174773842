<script>
    import Factorial from './factorial/Factorial.svelte'
    import Clockify from './clockify/Clockify.svelte'
    import Inventory from './inventory/Inventory.svelte'
    import TimelineCalendar from '../monitor/calendar/Calendar.svelte'
    import Modal from 'svelte-simple-modal';
    import { getUserId, getRole } from "../../js/localInfo";

    const role = getRole();
    const id = getUserId(); 
    let page = 0;
    let today = new Date();

    //Update Clockify
    setInterval(() => {
        const now = new Date();
        if (today.getDate() !== now.getDate()) today = now;
    }, 3600000); // 3600000 = 1h

</script>

<div id="myToolsContainer">
    <div id="pages">
        <button class="toolBtn { page === 0 ? 'btnSelected' : '' }" on:click={ () => page = 0 }>HOURS</button>
        <button class="toolBtn { page === 1 ? 'btnSelected' : '' }" on:click={ () => page = 1 }>DAYS</button>
        <button class="toolBtn { page === 2 ? 'btnSelected' : '' }" on:click={ () => page = 2 }>INVENTORY</button>
        <button class="toolBtn { page === 3 ? 'btnSelected' : '' }" on:click={ () => page = 3 }>CALENDAR</button>
        <!-- <button class="toolBtn { page === 4 ? 'btnSelected' : '' }" on:click={ () => page = 4 }> - </button> -->
    </div>
    <div id="toolContainer">
        <Modal closeButton={false}>
        {#if page === 0}
            {#key today}
            <Clockify {id} {role}/>
            {/key}
        {:else if page === 1}
            <Factorial />
        {:else if page === 2}
            <Inventory />
        {:else if page === 3}
            <TimelineCalendar {role}/>
        {/if}
        </Modal>
    </div>
</div>

<style>
    #myToolsContainer {
        flex-direction: row;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        color: #35425B !important;
    }

    #toolContainer {
        width: 100%;
        height: 96%;
    }

    #pages {
        width: 100%;
        height: 24px;
        display: flex;
    }

    .toolBtn {
        flex-grow: 1;
        margin: 0;
        padding: 0;
        border: none;
        background-color: #e7ecf1;
        border-radius: 16px 16px 0px 0px;
        color: #9b9b9b;
        font-weight: bold;
    }

    .btnSelected {
        color: #35425b;
        background-color: white;
    }
</style>