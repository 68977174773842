import { post } from "./labit-connection";

export default async function getClients() {
  const response = await post(
    `select c.* 
      from companyCategories as cc
      left join companies as c on c.company_id=cc.company
      where cc.category=3 and Active=1 ORDER BY Name;`
  );
  return response;
}
