<script>
  import LoginIcon from "../components/LoginIcon.svelte";
  import LoginTextEdit from "../components/LoginTextEdit.svelte";
  import LoginPasswordEdit from "../components/LoginPasswordEdit.svelte";
  import LoginButton from "../components/LoginButton.svelte";

  import { rpost, rememberPassword } from "../js/labit-connection";
  import {
    setUserId,
    setUserName,
    setRole,
    setPicture,
    setToken,
    setTester,
    setOutlookEmail,
    getToken,
    getPlatform,
  } from "../js/localInfo";
  import { encrypt, decrypt } from "../js/encryption";

  import { onMount } from "svelte";

  let iconColor = "var(--labit-login-icon-gray)";
  let imgIcon = "/images/login/cloud-logo.svg";

  let email = "";
  let passwd = "";
  let numAttempts = 4;

  let mode = "login"; // login, error, noAttempts, remember, rememberOK

  let loading = true;

  let fastLogin = () => {
    const token = getToken();
    if (token === "") {
      const settings = getCookie();
      if (settings) {
        setUserId(settings.id);
        setUserName(settings.name);
        setRole(settings.access);
        setPicture(settings.pic);
        setToken(settings.token);
        setTester(settings.tester);
        location.href = "/skylab-main/dashboard";
      } else {
        loading = false;
      }
    } else {
      loading = false;
    }
  };

  let changeMode = (newMode) => {
    mode = newMode;
  };

  let setCookie = (id, name, access, pic, token, tester) => {
    const cookie = {
      id,
      name,
      access,
      pic,
      token,
      tester,
    };
    const cookieT =
      "skylab-settings=" +
      encrypt(JSON.stringify(cookie)) +
      "; expires=Thu, 31 Dec 3000 12:00:00 UTC";
    return cookieT;
  };

  let getCookie = () => {
    let settings = null;
    const cookies = document.cookie;
    const tokens = cookies.split(";");
    const skylabToken = tokens.find((item) => {
      const toks = item.split("=");
      return toks.length > 0 && toks[0].includes("skylab-settings");
    });
    if (skylabToken) {
      const pos = skylabToken.indexOf("=");
      const value = skylabToken.substring(pos + 1);
      settings = JSON.parse(decrypt(value));
    }
    return settings;
  };

  let login = async () => {
    let ok = false;
    let id = -1;
    let access = "";
    let pic = "";
    let token = "";
    let name = "";
    let tester = false;
    if (email !== "" && passwd !== "") {
      const response = await rpost("Login", { username: email, pass: passwd });
      if (response.contact_id) {
        id = response.contact_id;
        name = response.Name;
        access = response.Access;
        pic = response.PictureURL;
        token = response.token;
        tester = response.tester === "1";
        ok = true;
        setOutlookEmail(email);
      }
    }
    if (ok) {
      setUserId(id);
      setUserName(name);
      setRole(access);
      setPicture(pic);
      setToken(token);
      setTester(tester);
      document.cookie = setCookie(id, name, access, pic, token, tester);
      location.href = "/skylab-main/dashboard";
    } else {
      numAttempts--;
      passwd = "";
      if (numAttempts === 0) {
        changeMode("noAttempts");
      } else {
        changeMode("error");
      }
    }
  };

  let sendRecoveryPassword = async () => {
    if (email !== "") {
      const response = await rememberPassword(email);
      if (response && response.status === "success") {
        changeMode("rememberOK");
      } else {
        alert("Failed to send recovery email. Please try again.");
      }
    } else {
      alert("Please enter your email address.");
    }
  };

  $: {
    switch (mode) {
      case "login":
        iconColor = "var(--labit-login-icon-gray)";
        imgIcon = "/images/login/cloud-logo.svg";
        numAttempts = 4;
        break;
      case "noAttempts":
      case "error":
        iconColor = "var(--labit-login-icon-red)";
        imgIcon = "/images/login/thunder.svg";
        break;
      case "remember":
        iconColor = "var(--labit-login-icon-gray)";
        imgIcon = "/images/login/rain.svg";
        break;
      case "rememberOK":
        iconColor = "var(--labit-login-icon-green)";
        imgIcon = "/images/login/check.svg";
        break;
    }
  }

  fastLogin();
</script>

{#if !loading}
  <div class="loginContainer">
    <a class="logo" href="https://labit.es" target="_blank">
      <img src="/images/logo-labit-website.svg" alt="Logo Icon" />
    </a>
    <div class="info">
      <LoginIcon {imgIcon} color={iconColor} />
      {#if mode === "login" || mode == "error" || mode === "noAttempts"}
        <div class="form formDiv">
          {#if mode === "noAttempts"}
            <span class="msg formDiv">
              Sorry, but you don't have further attempts.
            </span>
          {:else}
            <div class="formItem">
              <LoginTextEdit bind:value={email} placeholder="Email Address" />
            </div>
            <div class="formItem">
              <LoginPasswordEdit
                bind:value={passwd}
                placeholder="Password"
                onClickForgot={() => {
                  numAttempts = 4;
                  changeMode("remember");
                }}
                error={mode === "error"}
              />
              {#if mode === "error"}
                <div class="attempts">
                  <span
                    class="attempts"
                    on:click={() => changeMode("remember")}
                  >
                    {numAttempts}
                  </span>
                </div>
              {/if}
            </div>
          {/if}
          <div class="div" />
          {#if mode === "error" || mode === "noAttempts"}
            <div class="formItem button">
              <LoginButton
                text="I forgot my password"
                colorText="white"
                colorBackground="var(--labit-login-icon-red)"
                colorBorder="var(--labit-login-icon-red)"
                onClick={() => changeMode("remember")}
              />
            </div>
          {/if}
          {#if mode === "login" || mode === "error"}
            <div class="formItem button">
              <LoginButton
                text="Login"
                colorText="white"
                colorBackground="var(--labit-login-icon-gray)"
                colorBorder="var(--labit-login-icon-gray)"
                onClick={() => login()}
              />
            </div>
          {/if}
        </div>
      {:else if mode === "remember"}
        <span class="msg formDiv">
          That's okay, it happens! Enter your email below to reset your password.
        </span>
        <div class="form">
          <div class="formItem">
            <LoginTextEdit bind:value={email} placeholder="Email Address" />
          </div>
          <div class="formItem button">
            <LoginButton
              text="Send"
              colorText="white"
              colorBackground="var(--labit-login-icon-green)"
              colorBorder="var(--labit-login-icon-green)"
              onClick={async () => {
                await sendRecoveryPassword();
              }}
            />
          </div>
          <div class="formItem button">
            <LoginButton
              text="Back"
              colorText="var(--labit-darkgray)"
              colorBackground="transparent"
              colorBorder="var(--labit-darkgray)"
              onClick={() => changeMode("login")}
            />
          </div>
        </div>
      {:else}
        <span class="msg formDiv">
          This time try an easier one for you. May I suggest 123456?
        </span>
        <div class="form">
          <div class="formItem button">
            <LoginButton
              text="Back"
              colorText="var(--labit-darkgray)"
              colorBackground="transparent"
              colorBorder="var(--labit-darkgray)"
              onClick={() => changeMode("login")}
            />
          </div>
        </div>
      {/if}
    </div>
    <div class="signup">
      <span>By signing up, you agree with our Terms & Conditions</span>
    </div>
  </div>
{/if}

<style>
  div.loginContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }

  div.info {
    width: 422px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 134px;
  }

  div.form {
    width: 50%;
  }

  .formDiv {
    margin-top: -77px;
  }

  div.formItem {
    width: 100%;
    height: 29px;
    margin-bottom: 19px;
    position: relative;
  }

  div.button {
    height: 33px;
  }

  div.div {
    height: 15px;
  }

  div.signup {
    position: absolute;
    bottom: 23px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 10px;
    color: var(--labit-darkgray);
  }

  span.msg {
    font-size: 12px;
    color: var(--labit-darkgray);
    margin-bottom: 33px;
  }

  a.logo {
    position: absolute;
    top: 38px;
    left: 115px;
  }

  a.logo img {
    width: 48px;
  }

  div.attempts {
    color: white;
    background-color: var(--labit-login-icon-red);
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Animations */

  a.logo img {
    transition: opacity 0.5s linear 0s;
  }

  a.logo img:hover {
    opacity: 0.5;
  }
</style>
