<script>
  export let mapCenter; // {latitude, longitude}
  export let locations = []; // [ {latitude, longitude, name, onClick} ]
  export let zoom = 17;
  export let id = "";

  const GOOGLE_API_KEY = "AIzaSyA3J_Ted4QhRRuqKGIIxK1bLHlbhSoRlG4";
  const MAP_ID = "267c83c0c4583c11";

  let map;
  let markers = [];
  let loaded = false;

  // Inicialización del mapa
  window.initMap = function () {
    if (loaded) return; // Evitar inicializaciones múltiples

    const center = {
      lat: parseFloat(mapCenter.latitude),
      lng: parseFloat(mapCenter.longitude),
    };
    try {
      const mapElement = document.getElementById("googleMap-" + id);
      if (!mapElement) {
        console.error("Map container not found");
        return;
      }

      map = new google.maps.Map(mapElement, {
        zoom,
        center,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        mapId: MAP_ID,
      });

      addMarkers();
      console.log("Google Map initialized successfully");
      loaded = true;
    } catch (e) {
      console.error("Error initializing Google Map:", e);
    }
  };

  function addMarkers() {
    clearMarkers();
    locations
      .filter((location) => location.latitude && location.longitude)
      .forEach((location) => {
        const loc = {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        };

        if (isNaN(loc.lat) || isNaN(loc.lng)) {
          console.error("Invalid location coordinates:", location);
          return;
        }

        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: loc,
          title: location.name,
          map: map,
        });

        if (location.onClick) {
          marker.addListener("click", location.onClick);
        }

        markers.push(marker);
      });
  }

  // Limpiar Marcadores
  function clearMarkers() {
    markers.forEach((marker) => marker.setMap(null));
    markers = [];
  }

  // Cargar el script de Google Maps si no está cargado
  if (!window.google) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&libraries=marker`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  } else {
    window.initMap();
  }

  // Cargar el mapa si `locations` y `mapCenter` están disponibles
  $: if (locations.length > 0 && mapCenter) {
    if (loaded) {
      addMarkers();
    } else {
      try {
        window.initMap();
      } catch (e) {
        console.error("Error calling initMap:", e);
      }
    }
  }
</script>

<svelte:head></svelte:head>

<div class="googleMapsContainer" id={"googleMap-" + id} />

<style>
  div.googleMapsContainer {
    width: 100%;
    height: 100%;
    border-radius: 19px;
  }
</style>
