<script>
  export let userProfile;
  export let onClose;
  export let onSave;
  export let onRemove;
  export let action;
  export let newProfile;
  export let showMenu;
  export let refreshCompanyList;

  import Switch from "../components/Switch.svelte";
  //import DialogWeekdays from "../components/DialogWeekdays.svelte";
  //import DialogFileContainer from "../components/DialogFileContainer.svelte";
  import DialogExpandSpan from "../components/DialogExpandSpan.svelte";
  import DialogSpanEdit from "../components/DialogSpanEdit.svelte";
  import DialogAEdit from "../components/DialogAEdit.svelte";
  import DialogSpanEditPassword from "../components/DialogSpanEditPassword.svelte";
  import DialogSpanCombobox from "../components/DialogSpanCombobox.svelte";
  import DialogSpanCalendarPicker from "../components/DialogSpanCalendarPicker.svelte";
  import DialogSpanSuggestionEdit from "../components/DialogSpanSuggestionEdit.svelte";
  import Picture from "../components/Picture.svelte";
  import FileBoobleContainerSimple from "../components/FileBoobleContainerSimple.svelte";
  import PreviewRemoteFile from "../components/PreviewRemoteFile.svelte";
  import FileUploader from "../components/FileUploader.svelte";
  import MenuMyProfile from "../components/MenuMyProfile.svelte";
  import FileBoobleContainer from "../components/FileBoobleContainer.svelte";
  import DialogSmallButton from "../components/DialogSmallButton.svelte";
  import BorderButton from "../components/BorderButton.svelte";
  import CloseButton from "../components/CloseButton.svelte";
  import AssignedProjects from "../components/AssignedProjects.svelte";
  import Clockify from "../modules/me/clockify/Clockify.svelte";
  import Sharepoint from "../modules/personal-data/Sharepoint.svelte"

  import {
    getRole,
    getUserId,
    getPlatform,
    setOutlookSendEmail,
    setOpenTool,
  } from "../js/localInfo";
  import { post, rpost } from "../js/labit-connection";
  //import { removeAccents } from "../js/removeAccents";
  import { updateCityCode } from "../js/updateCityCode";
  import { openURL } from "../js/openURL";
  import { openNewWindow } from "../js/openNewWindow";
  import { insertCompanyBasic } from "../js/companies";
  import { randomString } from "../js/randomString";

  import {
    OUTLOOK_SEND_EMAIL_URL,
    SLACK_TEAM_URL,
  } from "../js/globalExternalURLs";

  import countriesJSON from "../data/countries";

  import { onMount } from "svelte";
  import { copyObject } from "../js/copyObject";

  import { openSlackUrlStore } from "../js/stores.js";
import InputTime from "../modules/me/clockify/InputTime.svelte";
import ObjectView from "../modules/revit-link/components/ObjectView.svelte";

  let mode = "public";
  let userSaved = null;
  let cardMode = "profile"; // profile, clockify, factorial, documents
  let currentTool = "";

  let errorName = false;
  let errorFullname = false;
  let errorCityCode2 = false;
  let errorCityCode3 = false;
  let error = false;

  let companies = [];
  let companiesInfo = [];
  let selectedCompany = null;
  let categories = [];
  let selectedCategory = null;

  let myRole = getRole();
  let myId = getUserId().toString();
  let myProfile = myId === userProfile.id;
  //showMenu = !myProfile;
  let platform = getPlatform();

  let numLaborContract = 0;
  let numPayroll = 0;
  let numAbsence = 0;
  let numCV = 0;
  let numOther = 0;

  let remoteFilePrev = "";

  let cities = [];
  let countries = [];
  let countriesSel = null;
  let cityCodes = [];
  let cityCode2 = "";
  let cityCode3 = "";
  let setCityCode2 = false;
  let setCityCode3 = false;

  let userRoles = [];
  let userRolesSel = null;

  let slackUrl = "";

  let newCompany = "";
  let showNewCompany = false;

  let askingForClosing = false;
  let savedProfile = null;
  let modified = false;

  let assignedProjects = [];
  let loadedAssignedProjects = -1;

  const CLOCKIFY_WEB = "https://clockify.me/tracker";
  const FACTORIAL_WEB = "https://app.factorialhr.com/dashboard";

  let weekTime = {
    monday: { pos: 0, value: '', error: false },
    tuesday: { pos: 1, value: '', error: false },
    wednesday: { pos: 2, value: '', error: false },
    thursday: { pos: 3, value: '', error: false },
    friday: { pos: 4, value: '', error: false }
  };

  const saveWeekTime = () => {
    const keys = Object.keys(weekTime);
    keys.forEach(key => {
      weekTime[key].error = weekTime[key].value === -1;
    });

    const error = keys.some(key => weekTime[key].error);
    if (!error) {
      const arr = keys.reduce((acc, key, i) => {
        if (weekTime[key].pos === i) acc.push(weekTime[key].value);
        return acc;
      }, []);

      post(`UPDATE people SET weekWorkDistribution = "${arr.join('|')}" WHERE contact_id = ${myId};`);
    }
  };

  const getWeekTime = async () => {
    let [res] = await post(`SELECT weekWorkDistribution FROM people WHERE contact_id = ${myId};`);
    if (res.weekWorkDistribution === undefined) res = '08:00|08:00|08:00|08:00|08:00';
    const timeParts = res.weekWorkDistribution.split('|');
    const keys = Object.keys(weekTime);
    timeParts.forEach((time, index) => {
      const i = keys.findIndex(key => weekTime[key].pos === index);
      weekTime[keys[i]].value = time;
    });
  };

  onMount(async () => {
    countries = countriesJSON.map((country) => {
      return {
        label: country.code + " - " + country.name,
        value: country.code,
      };
    });

    countries = countries.sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }
      return 0;
    });

    countriesSel = countries.find((item) => {
      return item.label === userProfile.company.officeCountry;
    });
    if (!countriesSel) {
      const c = countriesJSON.find((item) => {
        return item.name === "None";
      });
      countriesSel = {
        label: c.code + " - " + c.name,
        value: c.code,
      };
    }

    if (platform === "Web") {
      await getSlackUrl();
    }

    await getUserRoles();
    await getCityCodes();
    await getCities();
    await getCategories();
    await getCompanies();
    if (userProfile.user.user !== "") {
      const response = await rpost("GetPassword", {
        contact_id: userProfile.id,
      });
      userProfile.user.password = response[0];
    }

    if (newProfile) {
      userSaved = JSON.parse(JSON.stringify(userProfile));
      savedProfile = copyObject(userProfile);
    }

    setCityCodes();
    await getWeekTime();
  });

  let previewFile = (id) => {
    remoteFilePrev = btoa(unescape(encodeURIComponent(id)));
  };

  let getSlackUrl = async () => {
    let response = await post(
      `select url
        from slacks
        order by id asc`
    );
    if (response.length > 0) {
      slackUrl = response[0].url;
    }
  };

  let getUserRoles = async () => {
    let response = await post(
      `select name
        from userRoles
        order by id asc`
    );
    userRoles = response.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    userRolesSel = userRoles.find((item) => {
      return item.value === userProfile.platformAccess;
    });
  };

  let getCityCodes = async () => {
    let response = await post(
      `select name, code2, code3
        from city_codes
        order by name asc`
    );
    cityCodes = response.map((item) => {
      return {
        name: item.name,
        code2: item.code2,
        code3: item.code3,
      };
    });
  };

  let getCities = async () => {
    let response = await post(
      `select distinct City
        from people`
    );
    const citiesPeople = response.map((item) => {
      return item.City ? item.City : "";
    });

    response = await post(
      `select distinct City
        from companies`
    );
    const citiesCompanies = response.map((item) => {
      return item.City ? item.City : "";
    });

    cities = citiesPeople.concat(citiesCompanies);
    cities = [...new Set(cities)];
    cities.sort();
  };

  let getCompanies = async () => {
    if (selectedCategory) {
      const response = await post(
        `select company, Name
        from companyCategories as cc
        left join companies as c on c.company_id=cc.company
        where category=${selectedCategory.value} ORDER BY Name`
      );
      companiesInfo = response.map((item) => {
        return {
          id: item.company,
          name: item.Name,
          categoryId: selectedCategory.value,
        };
      });

      companies = companiesInfo.reduce((acc, curr) => {
        if (curr.categoryId === selectedCategory.value) {
          acc.push({
            value: curr.id,
            label: curr.name,
          });
        }
        return acc;
      }, []);
      selectedCompany = companies.find((item) => {
        return item.value === userProfile.company.id;
      });
      if (!selectedCompany) {
        selectedCompany = companies.find((item) => {
          return item.label === "Labit Group";
        });
        if (!selectedCompany && companies.length > 0) {
          selectedCompany = companies[0];
        }
      }
    } else {
      selectedCompany = companies[0];
    }

    companiesInfo = companiesInfo;
    companies = companies;
  };

  let getCategories = async () => {
    const response = await post(
      `select category_id, Name
        from categories`
    );
    categories = response.map((item) => {
      return {
        value: item.category_id,
        label: item.Name,
      };
    });
    selectedCategory =
      userProfile.company.categoryId === ""
        ? null
        : categories.find((item) => {
            return item.value === userProfile.company.categoryId;
          });
  };

  function toggleStatus() {
    userProfile.active = !userProfile.active;
  }

  function toggleAccess() {
    userProfile.platformAccess = !userProfile.platformAccess;
  }

  function clickWeekDay(weekday) {
    userProfile.timeTable.weekDays[weekday] =
      !userProfile.timeTable.weekDays[weekday];
  }

  let checkErrors = () => {
    errorName = false;
    //errorFullname = false;
    errorCityCode2 = false;
    errorCityCode3 = false;
    error = false;

    if (userProfile.card.name === "") {
      errorName = true;
    }
    /*if (userProfile.profile.fullName === "") {
      errorFullname = true;
    }*/

    if (userProfile.company.officeCity !== "") {
      if (cityCode2.trim() === "") {
        errorCityCode2 = true;
      }
      if (cityCode3.trim() === "") {
        errorCityCode3 = true;
      }
    }
    error = errorName || /*errorFullname ||*/ errorCityCode2 || errorCityCode3;
  };

  async function toggleAction() {
    if (action === "show") {
      userSaved = JSON.parse(JSON.stringify(userProfile));
      savedProfile = copyObject(userProfile);
      modified = true;
      action = "edit";
    } else {
      checkErrors();
      if (!error) {
        // Check whether it is a new city code
        if (
          userProfile.company.officeCity !== "" &&
          (!setCityCode2 || !setCityCode3)
        ) {
          await updateCityCode(
            userProfile.company.officeCity,
            cityCode2,
            cityCode3
          );

          await getCityCodes();
          setCityCodes();
        }

        onSave();
        action = "show";
        modified = false;
      }
    }
  }

  let xDialog = (option) => {
    switch (option) {
      case "request":
        if (action === "show") {
          onClose();
        } else {
          if (modified || newProfile) {
            if (!savedProfile.picImage) {
              savedProfile.picImage = userProfile.picImage;
            }
            const savedProfileS = JSON.stringify(savedProfile);
            const userProfileS = JSON.stringify(userProfile);
            /*console.log(savedProfileS);
            console.log(userProfileS);
            console.log(savedProfileS !== userProfileS);*/
            if (savedProfileS !== userProfileS) {
              askingForClosing = true;
            } else {
              if (newProfile) {
                onClose();
              } else {
                xDialog("close");
              }
            }
          } else {
            xDialog("close");
          }
        }
        break;
      case "save":
        toggleAction();
        askingForClosing = false;
        break;
      case "cancel":
        askingForClosing = false;
        break;
      case "close":
        if (newProfile) {
          onClose();
        } else {
          userProfile = JSON.parse(JSON.stringify(userSaved));
          askingForClosing = false;
          action = "show";
        }
        break;
    }
  };

  let onChangeCategory = async () => {
    await getCompanies();
  };

  let changeCardMode = (newMode) => {
    remoteFilePrev = "";
    cardMode = newMode;
  };

  let setCityCodes = () => {
    const code = cityCodes.find((cityCode) => {
      return cityCode.name === userProfile.company.officeCity;
    });
    if (code) {
      cityCode2 = code.code2;
      cityCode3 = code.code3;
      setCityCode2 = cityCode2 !== "";
      setCityCode3 = cityCode3 !== "";
    } else {
      cityCode2 = "";
      cityCode3 = "";
      setCityCode2 = false;
      setCityCode3 = false;
    }
  };

  let openEmail = (forceNewWindow) => {
    const url = OUTLOOK_SEND_EMAIL_URL + userProfile.card.email1;
    if (platform === "APP") {
      setOutlookSendEmail(url);
      if (forceNewWindow) {
        window.open("/outlook");
      } else {
        openURL("/outlook", true);
      }
    } else {
      window.open(url);
    }
  };
  
  
/* 
  let toggleMenuOptions = () => {
    showMenuOptions = !showMenuOptions;
    if (showMenuOptions) {
      window.$("div#fadedDiv").show();
      window.$("#menuShadow").show();
      window.$("#mainMenu").css("background-color", "transparent");
    } else {
      window.$("div#fadedDiv").hide();
      window.$("#menuShadow").hide();
      window
        .$("#mainMenu")
        .css("background-color", "var(--labit-background-gray)");
    }
  };
 */

  /*let openSlack = (forceNewWindow) => {
    const url = platform === "APP" ? "/skylab-main/slack" : slackUrl;
    if (platform === "APP") {
      if (forceNewWindow) {
        window.open(url);
      } else {
        location.href = url;
      }
    } else {
      window.open(url);
    }
  };*/

  let openNewCompany = (show) => {
    showNewCompany = show;
    if (showNewCompany) {
      newCompany = "";
    }
  };

  let saveNewCompany = async () => {
    if (newCompany !== "") {
      let response = await post(
        `select Name, category
          from companies as c
          left join companyCategories as cc on cc.company=c.company_id
          where Name='${newCompany}'`
      );
      if (response.length > 0) {
        const category = response[0].category;
        selectedCategory = categories.find((item) => {
          return item.value === category;
        });
        await getCompanies();
        selectedCompany = companies.find((item) => {
          return item.label === newCompany;
        });
      } else {
        const id = await insertCompanyBasic(newCompany, selectedCategory.value);
        const company = {
          label: newCompany,
          value: id,
        };
        companies.push(company);
        selectedCompany = company;
        companies = companies;

        refreshCompanyList = true;
      }
      openNewCompany(false);
    }
  };

  let clickZoom = (forceNewWindow) => {
    if (platform === "Web") {
      window.open(userProfile.zoom);
    } else {
      if (forceNewWindow) {
        openNewWindow(userProfile.zoom);
      } else {
        setOpenTool(userProfile.zoom);
        openURL("/tools/external", true);
      }
    }
  };

  let clickSlack = (forceNewWindow) => {
    if (platform === "Web") {
      window.open(SLACK_TEAM_URL + userProfile.slack);
    } else {
      if (forceNewWindow) {
        openNewWindow(SLACK_TEAM_URL + userProfile.slack);
      } else {
        openSlackUrlStore.set(
          SLACK_TEAM_URL + userProfile.slack + "&r=" + randomString(10)
        );
      }
    }
  };

  let getAssignedProjects = async () => {
    if (loadedAssignedProjects !== userProfile.id) {
      loadedAssignedProjects = userProfile.id;
      assignedProjects = [];
      /*const response = await post(
        `select distinct Name, FolderLabitCode
        from projectPeople as pp
        left join projects as p on p.project_id=pp.project
        where people=${userProfile.id}`
      );*/
      const response = await post(
        `select distinct pr.Name, FolderLabitCode
          from projectTeam as pt
          left join phase as ph on ph.id=pt.phase
          left join projects as pr on pr.project_id=ph.project
          where pt.people=${userProfile.id}`
      );
      assignedProjects = response.map((item) => {
        return {
          name: item.Name,
          code: item.FolderLabitCode,
        };
      });
    }
  };

  $: if (selectedCategory) {
    onChangeCategory();
  }

  $: if (selectedCompany) {
    userProfile.card.company = selectedCompany.label;
    userProfile.company.id = selectedCompany.value;
  }

  $: if (countriesSel) {
    userProfile.company.officeCountry = countriesSel.label;
  }

  $: if (userRolesSel) {
    userProfile.platformAccess = userRolesSel.label;
  }

  $: if (userProfile && action === "show") {
    getAssignedProjects();
  }

  //$: console.log(userProfile);
</script>

<div class="dialogContent">
  <!--{#if myProfile}
    <MenuMyProfile
      onClickProfile={() => changeCardMode("profile")}
      onClickClockify={() => changeCardMode("clockify")}
      onClickFactorial={() => changeCardMode("factorial")}
      onClickDocuments={() => changeCardMode("documents")}
      {onClose}
    />
  {/if}-->

  <!--<select bind:value={mode}>
    <option value="public">Public</option>
    <option value="private">Private</option>
    <option value="rrhh">RRHH</option>
  </select>-->

  <div class="card {action === 'edit' ? 'edit' : ''}">
    <!-- Header -->
    <div class="header {!myProfile ? 'notMyProfile' : ''}">
      <div class="userCard">
        <div class="cardContainer">
          <div class="presentation">
            <div class="userPic">
              {#if action === "show"}
                <Picture
                  image={userProfile.card.pic}
                  name={userProfile.card.name}
                  grayscale={true}
                  style={action !== "show" ? "display: none" : ""}
                  lastActivity={userProfile.lastActivity}
                  officeStatus={userProfile.officeStatus}
                  atHome={userProfile.atHome}
                />
              {:else}
                <FileUploader
                  bind:fileInfo={userProfile.picImage}
                  loadFromPath={userProfile.picPath}
                />
              {/if}
            </div>
            <div class="cardInfo">
              <span class="cardInfoName">{userProfile.card.name}</span>
              <div class="info">
                <div class="row">
                  <div class="column title">
                    <span>{showNewCompany ? "" : userProfile.card.company}</span
                    >
                  </div>
                  <div class="column title">
                    <span
                      >{showNewCompany
                        ? ""
                        : userProfile.card.companyRole}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span>{userProfile.company.officeCity}</span>
                  </div>
                  <div class="column">
                    <span>{userProfile.card.profRole}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span>{userProfile.card.email1}</span>
                  </div>
                  <div class="column">
                    <span>{userProfile.card.email2}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                    <span>{userProfile.card.telephone1}</span>
                  </div>
                  <div class="column">
                    <span>{userProfile.card.telephone2}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {#if myProfile}
        <MenuMyProfile
          onClickProfile={() => changeCardMode("profile")}
          onClickClockify={() => changeCardMode("clockify")}
          onClickNewClockify={() => {openURL("/me", true); }}
          onClickFactorial={() => changeCardMode("factorial")}
          onClickDocuments={() => changeCardMode("documents")}
          onClickOptions={() => changeCardMode("options")}
          {onClose}
        />
      {/if}
      {#if remoteFilePrev === ""}
        {#if askingForClosing}
          <div class="cardButtons">
            <div class="xDialog">
              <span class="xDialog">Save changes before closing?</span>
              <div class="xDialogRow">
                <div class="editButton cardIcon">
                  <BorderButton
                    text="Save"
                    onClick={() => xDialog("save")}
                    borderColor="var(--labit-light-red)"
                    fontColor="white"
                    backgroundColor="var(--labit-light-red)"
                  />
                </div>
                <div class="editButton cardIcon">
                  <BorderButton
                    text="No"
                    onClick={() => xDialog("close")}
                    borderColor="var(--labit-light-red)"
                    fontColor="var(--labit-light-red)"
                  />
                </div>
                <div class="editButton cardIcon">
                  <BorderButton
                    text="Cancel"
                    onClick={() => xDialog("cancel")}
                    borderColor="var(--labit-light-red)"
                    fontColor="var(--labit-light-red)"
                  />
                </div>
              </div>
            </div>
          </div>
        {:else if myProfile}
          <div class="cardButtonsProfile">
            {#if action === "edit"}
              <div class="cardIcon">
                <img
                  src="/images/save.svg"
                  alt="Save"
                  on:click={() => toggleAction()}
                />
              </div>
            {:else}
              <div class="editButton cardIcon">
                <BorderButton text="Edit" onClick={() => toggleAction()} />
              </div>
            {/if}
            <div class="cardIcon">
              <CloseButton
                size="38px"
                onClick={() => xDialog("request")}
                closing={action !== "show"}
              />
            </div>
          </div>
        {:else}
          {#if myRole === "Admin"}
            <div class="editButton cardIcon">
              <img class= "clocki-button" on:click={() => (cardMode = "newClockify")} src="/images/profile/hours.svg" alt="">
              <!-- <BorderButton
                text="Open clockify"
                onClick={() => (cardMode = "newClockify")}
              /> -->
            </div>
          {/if}
          <div class="cardButtons">
            {#if action === "edit"}
              {#if !newProfile && myRole === "Admin"}
                <div class="cardIcon">
                  <img
                    src="/images/trash.svg"
                    alt="Remove"
                    on:click={onRemove}
                  />
                </div>
              {/if}
              <div class="cardIcon">
                <img
                  src="/images/save.svg"
                  alt="Save"
                  on:click={() => toggleAction()}
                />
              </div>
            {:else}
              {#if userProfile.zoom}
                <div class="cardIcon">
                  <img
                    src="/images/tools/zoom.svg"
                    alt="Zoom"
                    on:click={() => clickZoom(false)}
                    on:contextmenu={(ev) => {
                      ev.preventDefault();
                      clickZoom(true);
                      return false;
                    }}
                  />
                </div>
              {/if}
              <div class="cardIcon">
                <img
                  src="/images/email.svg"
                  alt="Email"
                  on:click={() => openEmail(false)}
                  on:contextmenu={(ev) => {
                    ev.preventDefault();
                    openEmail(true);
                    return false;
                  }}
                />
              </div>
              {#if userProfile.slack}
                <div class="cardIcon">
                  <img
                    src="/images/chat.svg"
                    alt="Chat"
                    on:click={() => clickSlack(false)}
                    on:contextmenu={(ev) => {
                      ev.preventDefault();
                      clickSlack(true);
                      return false;
                    }}
                  />
                </div>
              {/if}
              {#if myRole === "Admin" || myId === userProfile.id}
                <div class="editButton cardIcon">
                  <BorderButton text="Edit" onClick={() => toggleAction()} /> 
                </div>
              {/if}
            {/if}
            <div class="cardIcon">
              <CloseButton
                size="38px"
                onClick={() => xDialog("request")}
                closing={action !== "show"}
              />
            </div>
          </div>
        {/if}
      {/if}
    </div>

    {#if remoteFilePrev !== ""}
      <div class="remoteFile">
        <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
      </div>
    {:else}
      <div
        class="body"
        style={cardMode === "newClockify" ? "flex-direction: column;" : ""}
      >
        {#if cardMode === "documents"}
          <div class="documents-sharepoint">
            <Sharepoint />
          </div>
          <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
        {:else if cardMode === "clockify"}
          {#if platform === "Web"}
            <iframe class="webvisor" title="External Tool" src={CLOCKIFY_WEB} />
          {:else}
            <webview
              class="webview_clockify webvisor"
              src={CLOCKIFY_WEB}
              webpreferences="nativeWindowOpen=yes, javascript=yes"
              allowpopups
              useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
            />
          {/if}
        {:else if cardMode === "factorial"}
          {#if platform === "Web"}
            <iframe
              class="webvisor"
              title="External Tool"
              src={FACTORIAL_WEB}
            />
          {:else}
            <webview
              class="webview_factorial webvisor"
              src={FACTORIAL_WEB}
              webpreferences="nativeWindowOpen=yes, javascript=yes"
              allowpopups
              useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
            />
          {/if}
        {:else if cardMode === "options"}
          <div class="options-container d-flex">
            <span class="big">Week time</span>
            <div class="options d-flex">
              <InputTime width='72px' bind:time={weekTime.monday.value} placeholder='Monday...' error={weekTime.monday.error} on:blur={saveWeekTime}/>
              <InputTime width='72px' bind:time={weekTime.tuesday.value} placeholder='Tuesday...' error={weekTime.tuesday.error} on:blur={saveWeekTime}/>
              <InputTime width='72px' bind:time={weekTime.wednesday.value} placeholder='Wednesday...' error={weekTime.wednesday.error} on:blur={saveWeekTime}/>
              <InputTime width='72px' bind:time={weekTime.thursday.value} placeholder='Thursday...' error={weekTime.thursday.error} on:blur={saveWeekTime}/>
              <InputTime width='72px' bind:time={weekTime.friday.value} placeholder='Friday...' error={weekTime.friday.error} on:blur={saveWeekTime}/>
            </div>
          </div>
        {:else if cardMode === "newClockify"}
          <Clockify id={userProfile.id} />
        {:else}
          <!-- Profile -->

          <div class="columnProfile columnData">
            <div class="box">
              <span
                class="columnTitle {action !== 'show' ? 'requiredElement' : ''}"
                >Name</span
              >
              <DialogSpanEdit
                bind:text={userProfile.card.name}
                action={myRole === "Admin" || myRole === "Analitycs"
                  ? action
                  : "show"}
                last={action === "show"}
                error={errorName}
              />
              <!--<span
              class="columnTitle {action !== 'show' ? 'requiredElement' : ''}"
              >Full Name</span
            >
            <DialogSpanEdit
              bind:text={userProfile.profile.fullName}
              action={myRole === "Admin" || myRole === "Analitycs"
                ? action
                : "show"}
              last={false}
              error={errorFullname}
            />-->
              <!--<span class="columnTitle">Nickname</span>
            <DialogSpanEdit
              bind:text={userProfile.profile.nickName}
              {action}
              last={action === "show" && userProfile.zoom === ""}
            />-->
              {#if action !== "show"}
                <span class="columnTitle">Private Zoom Link</span>
                <DialogSpanEdit
                  bind:text={userProfile.zoom}
                  action={myRole === "Admin" || myRole === "Analitycs"
                    ? action
                    : "show"}
                  last={false}
                />
                <span class="columnTitle">Slack Profile ID</span>
                <span class="columnTitle explanation"
                  >Usuario > Perfil > Tres Puntos > Copiar ID de miembro</span
                >
                <span class="columnTitle explanation">Example: U023LJSR27</span>
                <DialogSpanEdit
                  bind:text={userProfile.slack}
                  action={action}
                  last={true}
                />
              {/if}
              <!--{/if}-->
              <!--<span class="columnTitle">Picture</span>
            <DialogSpanEdit
              bind:text={userProfile.card.pic}
              {action}
              last={true} />-->
            </div>
            <div class="box">
              <span class="columnTitle">Category</span>
              <DialogSpanCombobox
                bind:selectedValue={selectedCategory}
                options={categories}
                action={myRole === "Admin" || myRole === "Analitycs"
                  ? action
                  : "show"}
                last={false}
              />
              {#if !showNewCompany}
                <div class="titleAdd">
                  <span class="columnTitle">Company</span>
                  {#if action !== "show"}
                    <img
                      class="fadedButton"
                      src="/images/plus-no-circle.svg"
                      alt="Add Company"
                      on:click={() => openNewCompany(true)}
                    />
                  {/if}
                </div>
                <DialogSpanCombobox
                  bind:selectedValue={selectedCompany}
                  options={companies}
                  action={myRole === "Admin" || myRole === "Analitycs"
                    ? action
                    : "show"}
                  last={false}
                />
              {:else}
                <span class="columnTitle">New Company</span>
                <DialogSpanEdit
                  bind:text={newCompany}
                  action="edit"
                  last={false}
                />
                <div class="titleButtons">
                  <DialogSmallButton
                    text="Add"
                    onClick={() => saveNewCompany()}
                  />
                  <DialogSmallButton
                    text="Close"
                    onClick={() => openNewCompany(false)}
                  />
                </div>
              {/if}
              <div class="tableInfo">
                <div class="tableInfoColumn">
                  <div class="tableInfoCell">
                    <span class="columnTitle">Office City</span>
                  </div>
                  <div class="tableInfoCell">
                    <DialogSpanSuggestionEdit
                      bind:text={userProfile.company.officeCity}
                      suggestions={cities}
                      onChange={() => setCityCodes()}
                      {action}
                      last={false}
                    />
                  </div>
                </div>
                {#if action !== "show"}
                  <div class="tableInfoColumn">
                    <div class="tableInfoCell">
                      <span class="columnTitle">2-Digit Code</span>
                    </div>
                    <div class="tableInfoCell">
                      <DialogSpanEdit
                        bind:text={cityCode2}
                        {action}
                        maxLength={2}
                        readOnly={setCityCode2}
                        error={errorCityCode2}
                        last={false}
                      />
                    </div>
                  </div>
                  <div class="tableInfoColumn">
                    <div class="tableInfoCell">
                      <span class="columnTitle">3-Digit Code</span>
                    </div>
                    <div class="tableInfoCell">
                      <DialogSpanEdit
                        bind:text={cityCode3}
                        {action}
                        maxLength={3}
                        readOnly={setCityCode3}
                        error={errorCityCode3}
                        last={false}
                      />
                    </div>
                  </div>
                {/if}
              </div>
              <span class="columnTitle">Office Country</span>
              <div class="tableInfoCell">
                <DialogSpanCombobox
                  bind:selectedValue={countriesSel}
                  options={countries}
                  searchable={true}
                  {action}
                  last={false}
                />
              </div>
              <span class="columnTitle">Position</span>
              <DialogSpanEdit
                bind:text={userProfile.card.companyRole}
                {action}
                last={false}
              />
              <span class="columnTitle">Mail</span>
              <DialogSpanEdit
                bind:text={userProfile.card.email1}
                {action}
                last={false}
              />
              <div class="tableInfo">
                <div class="tableInfoColumn">
                  <div class="tableInfoCell">
                    <span class="columnTitle">Phone</span>
                  </div>
                  <div class="tableInfoCell">
                    <DialogSpanEdit
                      bind:text={userProfile.card.telephone1}
                      {action}
                      last={false}
                    />
                  </div>
                </div>
                <div class="tableInfoColumn">
                  <div class="tableInfoCell">
                    <span class="columnTitle">Extension</span>
                  </div>
                  <div class="tableInfoCell">
                    <DialogSpanEdit
                      bind:text={userProfile.company.telephoneExtension}
                      {action}
                      last={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="tableInfoColumn">
                <div class="tableInfoCell">
                  <span class="columnTitle">Education</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    bind:text={userProfile.card.profRole}
                    {action}
                    last={false}
                  />
                </div>
              </div>
              <div class="tableInfo">
                <div class="tableInfoColumn">
                  <div class="tableInfoCell">
                    <span class="columnTitle">Education (Place)</span>
                  </div>
                  <div class="tableInfoCell">
                    <DialogSpanEdit
                      bind:text={userProfile.educationPlace}
                      {action}
                      last={true}
                    />
                  </div>
                </div>
                <div class="tableInfoColumn">
                  <div class="tableInfoCell">
                    <span class="columnTitle">Education (Year)</span>
                  </div>
                  <div class="tableInfoCell">
                    <DialogSpanEdit
                      bind:text={userProfile.educationYear}
                      {action}
                      last={true}
                      maxLength={4}
                      isNumeric={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <span class="columnTitle">Personal mail</span>
              <DialogSpanEdit
                bind:text={userProfile.card.email2}
                {action}
                last={false}
              />
              <span class="columnTitle">Personal phone number</span>
              <DialogSpanEdit
                bind:text={userProfile.card.telephone2}
                {action}
                last={false}
              />
            </div>
            {#if userProfile.card.company === "Labit Group"}
              <div class="box">
                <span class="columnTitle">Birth date</span>
                <DialogSpanCalendarPicker
                  bind:text={userProfile.birthDate}
                  calendarOptions={["Unknown"]}
                  {action}
                  last={true}
                />
              </div>
            {/if}
          </div>

          <!-- Personal -->

          <!--{#if mode === 'private' || mode === 'rrhh'}-->
          <div class="columnPersonal columnData">
            <div class="box">
              <span class="columnTitle">User</span>
              <DialogSpanEdit
                bind:text={userProfile.user.user}
                action={myRole === "Admin" || myRole === "Analitycs"
                  ? action
                  : "show"}
                last={false}
              />
              <span class="columnTitle">Password</span>
              <DialogSpanEditPassword
                bind:text={userProfile.user.password}
                {action}
                last={true}
              />
            </div>
            <div class="box">
              <div class="active">
                <span class="columnTitle">Active</span>
                <Switch
                  width={40}
                  height={22}
                  bind:checked={userProfile.active}
                  onClick={() => toggleStatus()}
                  readonly={action === "show" || myRole !== "Admin"}
                />
              </div>
            </div>
            <div class="box">
              <span class="columnTitle">Role</span>
              <div class="tableInfoCell">
                <DialogSpanCombobox
                  bind:selectedValue={userRolesSel}
                  options={userRoles}
                  searchable={false}
                  action={myRole === "Admin" ? action : "show"}
                  last={true}
                />
              </div>
              <!--<div class="active">
                <span class="columnTitle">Admin</span>
                <Switch
                  width={40}
                  height={22}
                  bind:checked={userProfile.platformAccess}
                  onClick={() => toggleAccess()}
                  readonly={action === 'show' || myRole !== 'Admin'} />
              </div>-->
            </div>
            <div class="box">
              <span class="columnTitle"
                >Assigned Projects ({assignedProjects.length})</span
              >
              <div class="assignedProjects customScrollbars">
                {#each assignedProjects as project}
                  <AssignedProjects
                    projectName={project.name}
                    projectCode={project.code}
                  />
                {/each}
              </div>
            </div>
            <div class="box dumbBox1" />
            <!--<div class="box">
          <span class="columnTitle">Personal mail</span>
          <DialogSpanEdit
            bind:text={userProfile.card.email2}
            {action}
            last={false}
            error={errorEmail2} />
          <span class="columnTitle">Personal phone number</span>
          <DialogSpanEdit
            bind:text={userProfile.card.telephone2}
            {action}
            last={false} />
        </div>
        <!--<div class="box">
            <span class="columnTitle">Address</span>
            <DialogSpanEdit
              text={userProfile.address.address}
              {action}
              last={false} />
            <div class="tableInfo">
              <div class="tableInfoColumn">
                <div class="tableInfoCell">
                  <span class="columnTitle">City</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    text={userProfile.address.city}
                    {action}
                    last={false} />
                </div>
              </div>
              <div class="tableInfoColumn">
                <div class="tableInfoCell">
                  <span class="columnTitle">Postcode</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    text={userProfile.address.postcode}
                    {action}
                    last={false} />
                </div>
              </div>
              <div class="tableInfoColumn">
                <div class="tableInfoCell">
                  <span class="columnTitle">Country</span>
                </div>
                <div class="tableInfoCell">
                  <DialogSpanEdit
                    text={userProfile.address.country}
                    {action}
                    last={false} />
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <span class="columnTitle">DNI / NIE</span>
            <DialogSpanEdit
              text={userProfile.identities.dni}
              {action}
              last={false} />
            <span class="columnTitle">Social Security Number</span>
            <DialogSpanEdit
              text={userProfile.identities.ssn}
              {action}
              last={false} />
          </div>
          <div class="box">
            <span class="columnTitle">Emergency contact</span>
            <DialogSpanEdit
              text={userProfile.emergencies.contact}
              {action}
              last={false} />
            <span class="columnTitle">Phone</span>
            <DialogSpanEdit
              text={userProfile.emergencies.telephone}
              {action}
              last={true} />
          </div>
          <div class="box">
            <span class="columnTitle">Birth date</span>
            <DialogSpanCalendarPicker
              text={userProfile.birthDate}
              calendarOptions={[]}
              {action}
              last={true} />
          </div>
          <div class="box">
            <span class="columnTitle">Comments</span>
            <DialogExpandSpan
              text={userProfile.comments}
              {action}
              last={true} />
          </div>-->
          </div>
          <!--{/if}-->

          <!-- RRHH -->

          <!--{#if mode === 'rrhh'}-->
          <div class="columnRRHH columnData">
            <!--<div class="box">
            <span class="columnTitle">User</span>
            <DialogSpanEdit
              bind:text={userProfile.user.user}
              {action}
              last={false} />
            <span class="columnTitle">Password</span>
            <DialogSpanEditPassword
              bind:text={userProfile.user.password}
              {action}
              last={true} />
          </div>
          <div class="box">
            <div class="active">
              <span class="columnTitle">Active</span>
              <Switch
                width={40}
                height={22}
                bind:checked={userProfile.active}
                onClick={() => toggleStatus()}
                readonly={action === 'show' || myRole !== 'Admin'} />
            </div>
          </div>
          <div class="box">
            <div class="active">
              <span class="columnTitle">Admin</span>
              <Switch
                width={40}
                height={22}
                bind:checked={userProfile.platformAccess}
                onClick={() => toggleAccess()}
                readonly={action === 'show' || myRole !== 'Admin'} />
            </div>
          </div>-->
            <!--<div class="box dumbBox2" />-->
            <!--<div class="box">
          <span class="columnTitle">Start date</span>
          <DialogSpanCalendarPicker
            text={userProfile.jobDates.startDate}
            calendarOptions={[]}
            {action}
            last={false} />
          <span class="columnTitle">End date</span>
          <DialogSpanCalendarPicker
            text={userProfile.jobDates.endDate}
            calendarOptions={['Indefinido']}
            {action}
            last={false} />
        </div>
        <div class="box">
          <div class="tableInfo">
            <div class="tableInfoColumn">
              <div class="tableInfoCell">
                <span class="columnTitle">Hours</span>
              </div>
              <div class="tableInfoCell">
                <DialogSpanEdit
                  text={userProfile.timeTable.hours}
                  {action}
                  last={false} />
              </div>
            </div>
            <div class="tableInfoColumn">
              <div class="tableInfoCell">
                <span class="columnTitle">Unds</span>
              </div>
              <div class="tableInfoCell">
                <DialogSpanEdit
                  text={userProfile.timeTable.unds}
                  {action}
                  last={false} />
              </div>
            </div>
          </div>
          <DialogWeekdays
            weekdays={userProfile.timeTable.weekDays}
            onClick={clickWeekDay}
            readonly={action === 'show'} />
        </div>-->
            {#if myId === userProfile.id && action !== "edit"}
              <div class="box boxFile customScrollbars">
                <span class="columnTitle">
                  Labor contract ({numLaborContract})
                </span>
                <FileBoobleContainerSimple
                  url={"/people/" +
                    userProfile.id +
                    " - " +
                    userProfile.card.name +
                    "/labor-contract"}
                  onPreviewFile={(id) => previewFile(id)}
                  bind:totalFiles={numLaborContract}
                />
              </div>
            {/if}
            {#if myId === userProfile.id && action !== "edit"}
              <div class="box boxFile customScrollbars">
                <span class="columnTitle">Payroll ({numPayroll})</span>
                <FileBoobleContainerSimple
                  url={"/people/" +
                    userProfile.id +
                    " - " +
                    userProfile.card.name +
                    "/payroll"}
                  onPreviewFile={(id) => previewFile(id)}
                  isPayroll={true}
                  bind:totalFiles={numPayroll}
                />
              </div>
            {/if}
            {#if (myId === userProfile.id || myRole === "Admin") && action !== "edit"}
              <div class="box boxFile customScrollbars">
                <span class="columnTitle">CV ({numCV})</span>
                <FileBoobleContainerSimple
                  url={"/people/" +
                    userProfile.id +
                    " - " +
                    userProfile.card.name +
                    "/cv"}
                  onPreviewFile={(id) => previewFile(id)}
                  bind:totalFiles={numCV}
                />
              </div>
            {/if}
            {#if myId === userProfile.id && action !== "edit"}
              <div class="box boxFile customScrollbars">
                <span class="columnTitle">Absence ({numAbsence})</span>
                <FileBoobleContainerSimple
                  url={"/people/" +
                    userProfile.id +
                    " - " +
                    userProfile.card.name +
                    "/absence"}
                  onPreviewFile={(id) => previewFile(id)}
                  bind:totalFiles={numAbsence}
                />
              </div>
            {/if}
            {#if myId === userProfile.id && action !== "edit"}
              <div class="box boxFile customScrollbars">
                <span class="columnTitle">Other ({numOther})</span>
                <FileBoobleContainerSimple
                  url={"/people/" +
                    userProfile.id +
                    " - " +
                    userProfile.card.name +
                    "/others"}
                  onPreviewFile={(id) => previewFile(id)}
                  bind:totalFiles={numOther}
                />
              </div>
            {/if}
            {#if numLaborContract < 3 || numPayroll < 3 || numAbsence < 3 || numOther < 3 || numCV < 3}
              <div class="box dumbBox2" />
            {/if}
          </div>
          <!--{/if}-->

          <!-- Public -->

          <div class="columnPublic columnData">
            <div class="box personal">
              {#if selectedCompany && selectedCompany.label === "Labit Group"}
                <div class="personal1 customScrollbars">
                  <span class="columnTitle">Briefing</span>
                  <DialogExpandSpan
                    bind:text={userProfile.briefing}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                  <span class="columnTitle">Sentence</span>
                  <DialogSpanEdit
                    bind:text={userProfile.sentence}
                    {action}
                    last={false}
                    breakOverflow={false}
                  />
                  <span class="columnTitle">Background</span>
                  <DialogExpandSpan
                    bind:text={userProfile.background}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                  <span class="columnTitle">Education</span>
                  <DialogExpandSpan
                    bind:text={userProfile.education}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                  <span class="columnTitle">Skills</span>
                  <DialogExpandSpan
                    bind:text={userProfile.skills}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                  <span class="columnTitle">Interest</span>
                  <DialogSpanEdit
                    bind:text={userProfile.interest}
                    {action}
                    last={false}
                    breakOverflow={false}
                  />
                  <span class="columnTitle">Previous Experience</span>
                  <DialogExpandSpan
                    bind:text={userProfile.previousExperience}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                  <span class="columnTitle">Languages</span>
                  <DialogExpandSpan
                    bind:text={userProfile.languages}
                    {action}
                    last={false}
                    noScrolls={true}
                    height="81px"
                  />
                </div>
              {/if}
              <div>
                <div class="linkedin">
                  <div>
                    <span class="columnTitle">Linkedin</span>
                    <DialogAEdit
                      bind:text={userProfile.linkedin}
                      {action}
                      embed={false}
                      slackLink={false}
                      last={false}
                    />
                  </div>
                  {#if action !== "show" || userProfile.linkedin === ""}
                    <img src="/images/linkedin.png" alt="Linkedin logo" />
                  {:else}
                    <a href={userProfile.linkedin} target="_blank">
                      <img src="/images/linkedin.png" alt="Linkedin logo" />
                    </a>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>

  <!--<div class="container {cardMode === 'documents' ? '' : 'hiddenElement'}">
    <FileBoobleContainer
      title="Labor Contract"
      url={"/people/" +
        userProfile.id +
        " - " +
        userProfile.card.name +
        "/labor-contract"}
      onPreviewFile={(id) => previewFile(id)}
      readOnly={true}
    />
    <FileBoobleContainer
      title="Payroll"
      url={"/people/" +
        userProfile.id +
        " - " +
        userProfile.card.name +
        "/payroll"}
      onPreviewFile={(id) => previewFile(id)}
      readOnly={true}
    />
    <FileBoobleContainer
      title="CV"
      url={"/people/" + userProfile.id + " - " + userProfile.card.name + "/cv"}
      onPreviewFile={(id) => previewFile(id)}
      readOnly={true}
    />
    <FileBoobleContainer
      title="Absence"
      url={"/people/" +
        userProfile.id +
        " - " +
        userProfile.card.name +
        "/absence"}
      onPreviewFile={(id) => previewFile(id)}
      readOnly={true}
    />
    <FileBoobleContainer
      title="Other"
      url={"/people/" +
        userProfile.id +
        " - " +
        userProfile.card.name +
        "/others"}
      onPreviewFile={(id) => previewFile(id)}
      readOnly={true}
    />
    <PreviewRemoteFile bind:fileUrl={remoteFilePrev} />
  </div>

  <div class="iframe {cardMode === 'clockify' ? '' : 'hiddenElement'}">
    {#if platform === "Web"}
      <iframe title="External Tool" src={CLOCKIFY_WEB} />
    {:else}
      <webview
        class="webview_clockify"
        src={CLOCKIFY_WEB}
        webpreferences="nativeWindowOpen=yes, javascript=yes"
        allowpopups
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
      />
    {/if}
  </div>

  <div class="iframe {cardMode === 'factorial' ? '' : 'hiddenElement'}">
    {#if platform === "Web"}
      <iframe title="External Tool" src={FACTORIAL_WEB} />
    {:else}
      <webview
        class="webview_factorial"
        src={FACTORIAL_WEB}
        webpreferences="nativeWindowOpen=yes, javascript=yes"
        allowpopups
        useragent="Mozilla/5.0 (Android 11; Mobile; LG-M255; rv:84.0) Gecko/84.0 Firefox/84.0"
      />
    {/if}
  </div>-->
</div>

<style>

  .clocki-button{
    width:32%;
    cursor: pointer;
  }

  .options-container {
    flex-direction: column;
    height: 640px;
  }

  .options-container > .options {
    align-items: center;
    color: #35425b;
    width: 400px;
    justify-content: space-between;
  }

  .options-container > .big {
    font-size: 1.2rem;
    margin: 0 0 10px 0;
  }

  .d-flex {
    display: flex;
  }

  div.dialogContent {
    background-color: var(--labit-background-gray);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
  }

  div.dialogContent:hover {
    width: 100%;
    height: 100%;
  }

  div.card {
    background-color: var(--labit-dialog-background);
    border-radius: 14px;
    padding: 10px;
    max-width: 1640px;
  }

  div.edit div.box {
    border-color: transparent !important;
  }

  div.header {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    padding: 15px 0px 15px 0px;
    justify-content: space-between;
    align-items: center;
  }

  div.body {
    background-color: white;
    width: 1620px;
    border-radius: 14px;
    padding: 23px;
    display: flex;
  }

  div.userCard {
    /*width: 349px;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1px 10px 0px 10px;
  }

  div.cardContainer {
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.presentation {
    width: 100%;
    /*height: 80px;*/
    display: flex;
    align-items: center;
    position: relative;
  }

  div.userPic {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  div.presentation span {
    /*font-size: 19px;
    font-weight: bold;*/
    color: var(--labit-card-gray);
  }

  div.info {
    width: 350px;
    /*height: 45%;*/
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }

  div.row {
    width: 100%;
    height: 25%;
    display: flex;
  }

  div.column {
    width: 50%;
    height: 100%;
    font-size: 11px;
    color: var(--labit-card-lightgray);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  div.title {
    font-size: 13px;
    color: var(--labit-card-gray);
  }

  div.cardButtons {
    height: 100%;
    display: flex;
    /*flex-grow: 1;*/
    justify-content: flex-end;
    /*padding-top: 35px;*/
    margin-right: 20px;
  }

  div.cardButtons div {
    height: 38px;
  }

  div.cardButtons div a {
    height: 100%;
  }

  div.cardButtons div img {
    height: 100%;
    cursor: pointer;
  }

  div.box {
    border-color: var(--labit-card-lightgray);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-radius: 14px;
    width: 367px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  div.box:not(:last-child) {
    margin-bottom: 10px;
  }

  div.columnData {
    display: flex;
    flex-direction: column;
  }

  div.columnData:not(:first-child) {
    margin-left: 38px;
  }

  span.columnTitle {
    width: 100%;
    font-size: 11px;
    color: var(--labit-card-gray);
    margin-bottom: 1px;
  }

  div.box img {
    width: 115px;
    margin-bottom: 8px;
  }

  div.tableInfo {
    display: flex;
    margin-bottom: 8px;
  }

  div.tableInfo:last-child {
    margin-bottom: 0px;
  }

  div.tableInfo span:empty:before {
    content: "\200b";
  }

  div.tableInfoColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  div.tableInfoColumn:not(:first-child) {
    margin-left: 21px;
  }

  div.tableInfoCell {
    display: flex;
  }

  div.active {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.active span {
    margin-bottom: 0px;
  }

  div.linkedin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 38px;
  }

  div.linkedin div {
    display: flex;
    flex-direction: column;
    min-width: 85%;
  }

  div.linkedin img {
    width: 23px;
    height: 23px;
    margin: 0px;
  }

  div.linkedin a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  div.personal {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.personal1 {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  div.cardButtonsProfile {
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 10px;
  }

  div.cardButtonsProfile img {
    height: 38px;
    cursor: pointer;
  }

  div.dumbBox1 {
    flex-grow: 1;
  }

  div.dumbBox2 {
    flex-grow: 1;
  }

  div.boxFile {
    max-height: 109px;
    overflow-y: scroll;
  }

  div.remoteFile {
    min-width: 1582px;
  }

  div.titleAdd {
    display: flex;
    align-items: center;
  }

  div.titleAdd span {
    width: auto;
  }

  div.titleAdd img {
    height: 7px;
    margin: 0px 0px 0px 5px;
    width: auto;
  }

  div.titleButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
  }

  div.editButton {
    width: 100px;
    height: 38px;
  }

  div.cardIcon {
    margin-left: 12px;
  }

  div.xDialog {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  span.xDialog {
    position: absolute;
    top: -28px;
    left: 12px;
    color: var(--labit-light-red);
    font-size: 13px;
  }

  div.xDialogRow {
    display: flex;
  }

  div.assignedProjects {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  span.explanation {
    font-style: italic;
  }

  div.cardInfo {
    display: flex;
    flex-direction: column;
  }

  span.cardInfoName {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  div.documents-sharepoint {
    width: 100%;
    /*min-height:400px;*/
  }

  .webvisor {
    height: 615px;
  }

  div.cardButtons img {
    transition: opacity 0.5s linear 0s;
  }

  div.cardButtons img:hover {
    opacity: 0.5;
  }

  div.linkedin a img {
    transition: opacity 0.5s linear 0s;
  }

  div.linkedin a img:hover {
    opacity: 0.5;
  }

  div.cardButtonsProfile img {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.cardButtonsProfile img:hover {
    opacity: 0.5;
    transition: opacity 0.5s linear 0s;
  }

  iframe,
  webview {
    width: 100%;
    height: 100%;
    border-width: 0px;
    border-radius: 19px;
  }
</style>
