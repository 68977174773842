<script>
  // Vertical Spacer
  export let height;
</script>

<div class="verticalSpacerContainer" style="height: {height}px" />

<style>
  div.verticalSpacerContainer {
    width: 100%;
    display: flex;
  }
</style>
