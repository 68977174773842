<script>
  import { getContext } from "svelte";
  import { getUserId } from "../../../js/localInfo";
  import { post } from "../../../js/labit-connection";
  import { countNonWorkingHours } from "../../../js/formatDateTime";
  import { tooltip } from "../../../components/tooltip/tooltip";
  import { absencesNotification, currentAbsenceId } from "../../../js/stores";

  import Calendar from "./Calendar.svelte";
  import AbsencesModal from "./AbsencesModal.svelte";
  import moment from "moment";

  const { open } = getContext("simple-modal");

  let showAddAbsenceButton = true;
  let [absenceTypes, events, holidays] = [[], [], []];
  let [nRequested, generatedDays, daysUsed, available] = [0, 0, 0, 0];
  let eventsObj = { accepted: [], holidaysUsed: [], requested: [], denied: [] };
  let [generatedThisYear,, generatedLastYear] = [0, 0, 0];

  let [generated, extra] = [0, 0];

  let absenceMode = "";

  const myId = getUserId();
  const toggleShowAbsenceModal = () =>
    (showAddAbsenceButton = !showAddAbsenceButton);

  const isANumber = (n) => typeof n === "number" && !isNaN(n);

  let hired;
  let maxHolidays = 0;
  let userName = "";
  const currentYear = moment().year();

  
  (async () => {
    const user = await post(
      `SELECT name, city, hired, extraHolidays, maxHolidays FROM people WHERE contact_id = ${myId}`,
    );
    absenceTypes = await post(
      "SELECT id, name, color, approvable FROM absence_type;",
    );
    holidays = await post(
      `SELECT name, startDate as date FROM general_holidays WHERE location = '${user[0].city}';`,
    );

    holidays = holidays.map((e) => {
      return { name: e.name, date: moment(e.date, "YYYY-MM-DD") };
    });

    maxHolidays = user[0].maxHolidays;
    userName = user[0].name;
    hired = moment(user[0].hired, "YYYY-MM-DD HH:mm:ss");
    const startOfYear = moment().startOf("year");
    const start = hired.isAfter(startOfYear) ? hired : startOfYear;

    generated = parseInt(getGeneratedDays(start, moment()));

    extra = isANumber(parseInt(user[0].extraHolidays))
      ? parseInt(user[0].extraHolidays)
      : 0;
    
    console.log("EXTRA", extra);

    await getEvents();
  })();

  const getGeneratedDays = (start, end) => {
    const freeTimeConstant = maxHolidays / 12;
    const days = end.diff(start, "days");
    console.log("DAYS",days);
    const monthDaysConstant = moment().isLeapYear() ? 366 / 12 : 365 / 12;
    const freeDays = (days / monthDaysConstant) * freeTimeConstant;
    console.log("FREEDAYSSS", freeDays);
    return freeDays.toFixed();
  };

  const getEvents = async () => {
    const res = await post(
      `SELECT a.id, at.id as absenceType, at.name, at.color, at.color_light, a.description, a.start, a.end, a.half, a.accepted_by_team_leader, a.accepted_by_ceo, at.approvable FROM absences AS a JOIN absence_type AS at ON at.id = a.absence_type_id where a.user_id = ${myId};`,
    );
    // Se devuelven solo las que no necesitan aprobación, o las que si lo necesitan y además están aprobadas

    events = [];
    events = res
      //.filter( (e) => ((e.approvable === "0") || (e.approvable === "1" && e.accepted_by_ceo === "1")) )
      .map((e) => {
        //const visible = ((e.approvable === "0") || (e.approvable === "1" && e.accepted_by_ceo === "1"));
        return {
          id: e.id,
          absenceType: e.absenceType,
          name: e.name,
          color: e.color,
          colorLight: e.color_light,
          description: e.description,
          start: new Date(parseInt(e.start)),
          end: new Date(parseInt(e.end)),
          half: e.half,
          acceptedByTL: e.accepted_by_team_leader,
          acceptedByCEO: e.accepted_by_ceo,
          editable: true,
          visible: true,
        };
      });

    const h = holidays.map((e) => {
      const date = e.date.toDate();
      return {
        name: e.name,
        colorLight: "#F45B5B",
        description: "General holidays",
        start: date,
        end: date,
        editable: false,
        visible: true,
      };
    });

    events = h.concat(events);
    events = events.concat({
      name: "today",
      start: moment().toDate(),
      end: moment().toDate(),
      color: "#000000",
      colorLight: "#000000",
      editable: false,
      visible: true,
    });

    const filteredAbsences = res.filter(
      (absence) => absence.absenceType !== "21",
    );

    const durationCalculation = (start, end, halfDay) => {
      // Convertimos las fechas de inicio y fin a un formato que solo tenga en cuenta el día
      const startDate = start.startOf("day");
      const endDate = end.startOf("day");

      if (halfDay === "1") {
        return 0.5;
      }

      // Verificamos si las fechas de inicio y fin son el mismo día
      if (startDate.isSame(endDate)) {
        // Si son el mismo día, la duración es 1
        return 1;
      } else {
        // Si no son el mismo día, calculamos la duración como antes
        const end2 = moment(end).add(1, "day");
        return (
          end2.diff(start, "hours") / 24 -
          countNonWorkingHours(start, end2, holidays, "all") / 8
        );
      }
    };

    eventsObj = { accepted: [], holidaysUsed: [], requested: [], denied: [] };
    eventsObj = filteredAbsences.reduce((acc, e) => {
      const start = moment(e.start, "x");
      const end = moment(e.end, "x");
      const now = moment();
      const duration = durationCalculation(start, end, e.half);

      const event = {
        id: e.id,
        absenceType: e.absenceType,
        start: start,
        end: end,
        description: e.description,
        duration: duration,
        half: e.half,
      };

      if (e.approvable === "1") {
        if (
          (e.accepted_by_ceo === "1" && e.accepted_by_team_leader === "1") ||
          e.accepted_by_ceo === "1"
        ) {
          // Ausencias aceptables aceptadas
          if (now.isAfter(end)) acc["holidaysUsed"].push(event);
          else acc["accepted"].push(event);
        } else if (
          e.accepted_by_ceo === "-1" ||
          e.accepted_by_team_leader === "-1"
        ) {
          acc["denied"].push(event);
        } else if (e.accepted_by_ceo === "0") {
          if (now.isAfter(end)) acc["denied"].push(event);
          else acc["requested"].push(event);
        }
      }

      return acc;
    }, eventsObj);

    let start;
    let year;
    const thisYearStart = moment().startOf("year");
    const pastYearStart = moment().subtract(1, "year").startOf("year");
    const pastYearEnd = moment().subtract(1, "year").endOf("year");
    const START_VACATION_YEAR = 2022;
    const hiredYear = hired.year();
    console.log("hired yearrrrrr", hiredYear);
    const today = moment();
    
    // Si la fecha de contratación es del año actual, no se generan días en el año anterior
    if (hired.isSameOrAfter(thisYearStart)) {
      start = hired;
    } else {
      if (hiredYear < START_VACATION_YEAR) {
        // Caso 2: Contratación antes de START_VACATION_YEAR
        start = moment([START_VACATION_YEAR]);
      } else if (hiredYear === START_VACATION_YEAR || hiredYear === pastYearStart.year()) {
        // Casos 3 y 4: Contratación en 2022 o en el año anterior (2023)
       start = hired;
      } else {
        // Otros casos
      start = pastYearStart;
      }
    }

    if(hired.isSameOrAfter(pastYearStart) || hiredYear === thisYearStart.year()){
      year = hired;
    } else {
      year = pastYearStart
    }
    
    const generatedAllTime = parseInt(getGeneratedDays(start, today));
    const generatedYear = parseInt(getGeneratedDays(thisYearStart, today));
    const generatedPastYear = parseInt(getGeneratedDays(year,pastYearEnd));
    console.log("GENERATED YEARRRRR",generatedYear);
    console.log("GENERATED ALL TIME",generatedAllTime);
    console.log("GENERATED PAST YEAR",generatedPastYear);

    const thisYear = moment().year();

    // Calcular los días de vacaciones utilizados en 2022
    const nHolidaysUsed2022 = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear - 2 // -3 para pasar a ser 2022
          ? e.duration
          : 0),
      0,
    );
    console.log("nHOLIDAYSUSEDPASTYEAR TWOOOOO", nHolidaysUsed2022);
    
    // Calcular los días de vacaciones utilizados en el año anterior (2023)
    const nHolidaysUsed2023 = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear - 1 // -2 para pasar a ser 2023
          ? e.duration
          : 0),
      0,
    );
    console.log("nHOLIDAYSUSEDPASTYEAR", nHolidaysUsed2023);

    // Calcular los días de vacaciones utilizados en 2024
    const nHolidaysUsed = eventsObj["holidaysUsed"].reduce(  // Cambiar el nombre
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear // -1 para pasar a ser 2024
        ? e.duration : 0),
        0,
    );
    nRequested = eventsObj["requested"].reduce((acc, e) => acc + e.duration, 0)
    console.log("nHOLIDAYSUSEDthisYear", nHolidaysUsed);

     // Calcular los días de vacaciones utilizados en 2025
     /* const nHolidaysUsed = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear ? e.duration : 0),
        0,
    );
    nRequested = eventsObj["requested"].reduce((acc, e) => acc + e.duration, 0)
    console.log("nHOLIDAYSUSEDthisYear", nHolidaysUsed); */
    
    // Actualizar los días generados totales con los días del año pasado y descontar los días utilizados
    generatedDays = generatedAllTime - nHolidaysUsed2023 - nHolidaysUsed2022 + extra;  // Actualizar con nHolidaysUsed2024
    generatedLastYear = generatedAllTime - nHolidaysUsed2023 - nHolidaysUsed2022 + extra - generatedYear; // Actualizar con nHolidaysUsed2024
    daysUsed = nHolidaysUsed
    available = generatedDays - daysUsed
    console.log("GENERATEDDAYS", generatedDays);
  };

  const absenceFn = async (e) => {
    const now = new Date();
    let query = "";
    let result;
    switch (e.action) {
      case "edit":
        query = `UPDATE absences SET description="${e.description}", start="${e.start}", end="${e.end}", requested_time="${now.getTime()}", half="${e.half}", absence_type_id="${e.type}", accepted_by_team_leader=0, accepted_by_ceo=0 where id=${e.id};`;
        result = await post(query);
        break;
      case "delete":
        query = `DELETE FROM absences WHERE id=${e.id};`;
        result = await post(query);
        break;
      case "add":
        query = `INSERT INTO absences (description, start, end, accepted_by_team_leader, accepted_by_ceo, half, requested_time, user_id, absence_type_id) VALUES ("${e.description}", "${e.start}", "${e.end}", 0, 0, ${e.half ? 1 : 0}, "${now.getTime()}", ${e.userId}, ${e.type});`;
        result = await post(query);
            
        if (result && result.length > 0) {
                const insertedId = result[0];
                currentAbsenceId.set(insertedId); 
                const detailsQuery = `SELECT a.description, a.start, a.end, t.Name, t.color FROM absences a INNER JOIN absence_type t ON(absence_type_id = t.id) WHERE a.id = ${insertedId};`;
                const detailsResult = await post(detailsQuery);
                if (detailsResult && detailsResult.length > 0) {
                    const details = detailsResult[0]; 
                    handleNewAbsence(details); 
                }
            }
            break;
    }
    showAddAbsenceButton = true;
    await getEvents();
  };


  const openAbsenceModal = (e, action = "add") => {
    const now = new Date();
    const start = e.detail.date || e.detail.startDate || e.detail.start;
    const end = e.detail.date || e.detail.endDate || e.detail.end;
    const event = events.find(
      (event) => event.start <= start && event.end >= end,
    );
    if (event !== undefined) {
      e.event = event;
      e.detail.start = start;
      e.detail.end = end;
      action = "edit";
      if (event.editable && event.start > now)
        open(AbsencesModal, {
          mode: absenceMode,
          action: "edit",
          options: e,
          absenceTypes: absenceTypes,
          myId: myId,
          events: events,
          absenceFn,
        });
    } else if (action === "add" && start > now) {
      open(AbsencesModal, {
        mode: absenceMode,
        action: "add",
        options: e,
        absenceTypes: absenceTypes,
        myId: myId,
        events: events,
        absenceFn,
      });
    }
  };

  function handleNewAbsence(details) {
    absencesNotification.set({ show: true, userName: userName, details: details });
  };

</script>

<div id="factorialContainer" class="flex">
  <div id="calendarContainer" class="flex flex-col">
    <Calendar
      {events}
      {absenceTypes}
      on:dayClicked={(e) => {
        absenceMode = "day";
        openAbsenceModal(e);
      }}
      on:rangeSelected={(e) => {
        absenceMode = "range";
        openAbsenceModal(e);
      }}
    />
  </div>
  <div id="menu" class="flex flex-col">
    <div id="downloadAndCountersContainer">
      <div id="counters" class="flex">
        <div class="daysContainer flex flex-col">
          <span
            style="cursor: pointer;"
            class="days"
            use:tooltip={{ x: -92, y: -52 }}
            description={`Generated ${moment().year()} days: ${generated} | Past year days: ${generatedLastYear}`}
            >{generatedDays}</span
          >
          <span class="tag">Generated</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{available}</span>
          <span class="tag">Available</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{daysUsed}</span>
          <span class="tag">Used</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{nRequested}</span>
          <span class="tag">Requested</span>
        </div>
      </div>
    </div>
    {#if showAddAbsenceButton}
      <button id="addAbsenceBtn" on:click={toggleShowAbsenceModal}>
        <img src="/images/new/plus.svg" alt="Add" />
        <span>New absence</span>
      </button>
      <div id="absencesContainer">
        <!-- eventsObj -->
        {#if eventsObj["requested"].length > 0}
          <div>
            <span class="absenceTitle">Requested absences</span>
            {#each eventsObj["requested"] as { id, absenceType, start, end, description, duration, half }}
              <div
                class="absence"
                on:click={() => {
                  openAbsenceModal(
                    {
                      detail: {
                        id: id,
                        absenceType: absenceType,
                        description: description,
                        start: start,
                        end: end,
                        half: half,
                      },
                    },
                    "edit",
                  );
                }}
              >
                <span class="width-200"
                  >{start.format("DD MMM")} - {end.format("DD MMM")}
                </span>
                <span>{duration} days</span>
                <img
                  src="/images/new/arrow-right-light.svg"
                  alt="More Options"
                />
              </div>
            {/each}
          </div>
        {/if}
        {#if eventsObj["accepted"].length > 0}
          <div>
            <span class="absenceTitle">Accepted absences</span>
            {#each eventsObj["accepted"] as { start, end, duration }}
              <div class="absence" style="cursor: auto">
                <span class="width-200"
                  >{start.format("DD MMM")} - {end.format("DD MMM")}
                </span>
                <span>{duration} days</span>
              </div>
            {/each}
          </div>
        {/if}
        {#if eventsObj["denied"].length > 0}
          <div>
            <span class="absenceTitle">Denied absences</span>
            {#each eventsObj["denied"] as { id, absenceType, start, end, description, duration, half }}
              <div
                class="absence"
                on:click={() => {
                  openAbsenceModal(
                    {
                      detail: {
                        id: id,
                        absenceType: absenceType,
                        description: description,
                        start: start,
                        end: end,
                        half: half,
                      },
                    },
                    "edit",
                  );
                }}
              >
                <span class="width-200"
                  >{start.format("DD MMM")} - {end.format("DD MMM")}
                </span>
                <span>{duration} days</span>
              </div>
            {/each}
          </div>
        {/if}
        {#if eventsObj["holidaysUsed"].length > 0}
          <div>
            <span class="absenceTitle">Past absences</span>
            {#each eventsObj["holidaysUsed"].filter(({start}) => moment(start).year() === currentYear) as { id, absenceType, start, end, description, duration, half }}
              <div
                class="absence"
                on:click={() => {
                  openAbsenceModal(
                    {
                      detail: {
                        id: id,
                        absenceType: absenceType,
                        description: description,
                        start: start,
                        end: end,
                        half: half,
                      },
                    },
                    "edit",
                  );
                }}
              >
                <span class="width-200"
                  >{start.format("DD MMM")} - {end.format("DD MMM")}
                </span>
                <span>{duration} days</span>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {:else}
      <div id="addAbsenceModal">
        <AbsencesModal
          {myId}
          {events}
          {absenceTypes}
          {absenceFn}
          on:close={() => {
            showAddAbsenceButton = true;
          }}
        />
      </div>
    {/if}
  </div>
</div>

<style>
  #addAbsenceBtn {
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 12px 0;
    cursor: pointer;
  }

  #addAbsenceBtn span {
    font-size: 16px;
  }

  .tag {
    font-weight: normal;
    color: #818181;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  #factorialContainer {
    flex-direction: row;
    width: 100%;
    background-color: white;
    height: 100%;
    border-radius: 0 0 19px 19px;
    color: #35425b !important;
  }

  #menu {
    width: 30%;
    height: 100%;
    padding: 20px;
  }

  #menu > * {
    margin: 20px 0;
  }

  div#absencesContainer {
    width: 100%;
    max-height: 540px;
    overflow: auto;
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    gap: 12px;
  }

  div#absencesContainer > div {
    padding: 16px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
  }

  div.absence {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    align-items: baseline;
  }

  div.absence > img {
    height: 16px;
  }

  div.absence > * {
    flex-grow: 1;
  }

  span.absenceTitle {
    padding: 0 0 16px 0;
    display: block;
    font-size: 20px;
  }

  span.width-200 {
    width: 200px;
  }

  #calendarContainer {
    padding: 20px;
    width: 70%;
    height: 100%;
  }

  #counters {
    justify-content: space-between;
  }

  div#downloadAndCountersContainer {
    padding: 0 32px;
  }

  div#downloadAndCountersContainer > div {
    padding: 8px 0;
  }

  .daysContainer {
    text-align: center;
  }

  .daysContainer > .days {
    font-size: 40px;
    height: 38px;
  }
</style>
