<script>
  export let projectId = -1;
  export let boxTitle;

  import DashboardTitle from "../../../../components/dashboard/DashboardTitle.svelte";
  import Picture from "../../../../components/Picture.svelte";
  //import ComboboxEvent from "../../../../modules/calendar/screens/components/ComboboxEvent.svelte";
  import Combobox from "../../../../components/Combobox.svelte";
  import { onMount } from "svelte";
  import { getUserId, getRole } from "../../../../js/localInfo";
  import { post } from "../../../../js/labit-connection.js";
  //import AppsListBox from "../../../pdashboard/screens/components/AppsListBox.svelte";
  //import Company from "../../../license-manager/components/Company.svelte";
  import moment from 'moment';

  let myId = getUserId();
  let myRole = getRole();

  let licenseToBeAssigned = {
    id: "",
    contact: myId,
    suscription: "",
    project: "",
    date: moment().format("YYYY-MM-DD"),
  };

  let assignLicenseMenu = false;

  let companySelected = null;
  let projectSelected = null;
  let productSelected = null;
  let keySelected = null;
  let suscriptionSelected = {
    label: "",
    value: "",
    seats: 0,
  };

  let peopleLicenses = [];
  let peopleLicenseX = [];
  let myLicenses = [];
  let optionsProjects = [];
  let optionCompanies = [];
  let optionProducts = [];
  let optionKeys = [];
  let optionSuscriptions = [];
  let errorProject = false;
  let errorCompany = false;
  let errorProduct = false;
  let errorKey = false;
  let companiesList = [];
  let productsList = [];
  let keysList = [];
  let suscriptionsList = [];

  onMount(async () => {
    await loadActiveProjects();
    await loadCompanies();
    await createProductsList();
    await loadMyLicenses();
    await loadPeopleLicenses();
    await createKeysList();
    await createSuscriptionsList();
    prepareOptionsComboboxList();
    if (projectId !== -1) {
      selectProject();
    }
  });

  let loadMyLicenses = async () => {
    myLicenses =
      await post(`SELECT la.id, la.suscription, la.contact, la.project, la.date, ls.contractNumber AS contractNumber, lp.name AS productName, lp.pictureURL, lk.username
    FROM licenseAssignments AS la
    LEFT JOIN licenseSuscriptions AS ls ON ls.id = la.suscription 
    LEFT JOIN licenseProducts AS lp ON ls.product = lp.id
    LEFT JOIN licenseCompanies AS lc ON lc.id = lp.company
    LEFT JOIN licenseKeys AS lk ON lk.id = ls.license
    WHERE contact = ${myId}`);
  };

  let loadPeopleLicenses = async () => {
    peopleLicenses =
      await post(`SELECT la.id, la.suscription, p.Name AS username, p.PictureUrl AS pic, p.lastActivity, p.atHome, ps.description AS profileStatusDescription, ps.iconImg AS profileStatusIcon
    FROM licenseAssignments AS la
    LEFT JOIN people AS p ON p.contact_id = la.contact
    LEFT JOIN profileStatus AS ps ON ps.id = p.profileStatus`);

    peopleLicenses = peopleLicenses.map((p) => {
      p.officeStatus = {
        description: p.profileStatusDescription,
        icon: p.profileStatusIcon,
      };
      return p;
    });
  };

  // Loading active projects into the ComboBox
  let loadActiveProjects = async () => {
    optionsProjects = [];
    // Loading of active projects from the database
    optionsProjects = await post(
      `SELECT p.project_id, p.Name AS projectName
      FROM projects AS p
      LEFT JOIN projectPeople AS ppe ON p.project_id = ppe.project
      WHERE p.active=1 and ppe.people = ` + myId
    );
  };

  let loadCompanies = async () => {
    companiesList = await post(
      `SELECT id, name, pictureURL FROM licenseCompanies`
    );
  };

  let createProductsList = async () => {
    productsList = await post(
      `SELECT id, name, pictureURL, company FROM licenseProducts`
    );
  };

  let createKeysList = async () => {
    keysList = await post(
      `SELECT id, username, passwd, company, active FROM licenseKeys
      WHERE active = 1`
    );
    keysList = keysList.map((key) => {
      key.active = key.active === "1" ? true : false;
      return key;
    });
  };

  let createSuscriptionsList = async () => {
    suscriptionsList =
      await post(`SELECT ls.id, ls.contractNumber, ls.seats, ls.type, ls.term, ls.expiringDate, ls.renewalComments, ls.renewalStatus, ls.product, ls.license, username, passwd, lc.id as companyId
      FROM licenseSuscriptions AS ls
      LEFT JOIN licenseKeys AS lk ON lk.id=ls.license
      LEFT JOIN licenseCompanies AS lc ON lc.id=lk.company
      WHERE ls.renewalStatus = "Active"`);

    suscriptionsList = suscriptionsList.map((suscription) => {
      suscription.expiringDate = moment(
        suscription.expiringDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
      return suscription;
    });

    let sus = [];
    for (let i = 0; i < suscriptionsList.length; i++) {
      const s = suscriptionsList[i];
      const response = await post(
        `select name
          from licenseRoles
          where keyId=${s.license}`
      );
      const roles = response.map((item) => {
        return item.name;
      });
      if (response.length === 0 || roles.includes(myRole)) {
        sus.push(s);
      }
    }
    suscriptionsList = sus;

    let companiesListFiltered = [];

    // Filter companies with subscriptions.
    for (let i = 0; i < companiesList.length; i++) {
      const company = companiesList[i];
      for (let j = 0; j < suscriptionsList.length; j++) {
        const suscription = suscriptionsList[j];
        if (suscription.companyId === company.id) {
          companiesListFiltered.push(company);
        }
      }
    }

    companiesList = companiesListFiltered;
  };

  let prepareOptionsComboboxList = () => {
    // Projects
    /*optionsProjects = optionsProjects.map((pro) => {
      return { label: pro.projectName, value: pro.project_id };
    });
    // Companies
    optionCompanies = companiesList.map((com) => {
      return { label: com.name, value: com.id };
    });*/
    optionsProjects = optionsProjects.reduce((acc, curr) => {
      const pos = acc.findIndex((item) => {
        return curr.project_id === item.value;
      });
      if (pos === -1) {
        acc.push({
          value: curr.project_id,
          label: curr.projectName,
        });
      }
      return acc;
    }, []);

    optionCompanies = companiesList.reduce((acc, curr) => {
      const pos = acc.findIndex((item) => {
        return curr.id === item.value;
      });
      if (pos === -1) {
        acc.push({
          value: curr.id,
          label: curr.name,
        });
      }
      return acc;
    }, []);
  };

  let selectProject = () => {
    projectSelected = optionsProjects.find((item) => {
      return item.value === projectId;
    });
  };

  let loadProducts = (companyId) => {
    // Data mapping.
    optionProducts = productsList
      .filter((pro) => {
        return pro.company === companyId;
      })
      .map((pro) => {
        return { label: pro.name, value: pro.id };
      });
    productSelected = optionProducts[0];
  };

  let loadKeys = (companyId) => {
    // Data mapping.
    optionKeys = keysList
      .filter((key) => {
        return key.company === companyId;
      })
      .map((key) => {
        return { label: key.username, value: key.id };
      });
    keySelected = optionKeys[0];
  };

  let loadSuscriptions = (productId, keyId) => {
    // Data mapping.
    optionSuscriptions = suscriptionsList
      .filter((sus) => {
        return sus.product === productId && sus.license === keyId;
      })
      .map((sus) => {
        return { label: sus.contractNumber, value: sus.id, seats: sus.seats };
      });
    if (optionSuscriptions.length) {
      suscriptionSelected = optionSuscriptions[0];
    } else {
      suscriptionSelected = {
        label: "",
        value: "",
        seats: 0,
      };
    }
  };

  let resetErrors = () => {
    errorProject = false;
    errorCompany = false;
    errorProduct = false;
    errorKey = false;
  };

  let resetSelectedCombobox = () => {
    optionProducts = [];
    optionKeys = [];
    optionSuscriptions = [];
    companySelected = null;
    projectSelected = null;
    productSelected = null;
    keySelected = null;
    suscriptionSelected = {
      label: "",
      value: "",
      seats: 0,
    };
  };

  let validateLicenseAssignment = async () => {
    // Load the application and appkey indexes.
    errorProject = false;
    errorCompany = false;
    errorProduct = false;
    errorKey = false;
    //  Manage errors.
    if (
      !projectSelected ||
      !companySelected ||
      !productSelected ||
      !keySelected
    ) {
      if (!projectSelected) {
        errorProject = true;
      }
      if (!companySelected) {
        errorCompany = true;
      }
      if (!productSelected) {
        errorProduct = true;
      }
      if (!keySelected) {
        errorKey = true;
      }
    } else {
      // Insert Assignment
      const responseAssignment = await post(
        `INSERT INTO licenseAssignments (suscription, contact, project, date)
        VALUES (${licenseToBeAssigned.suscription}, ${licenseToBeAssigned.contact}, ${licenseToBeAssigned.project}, '${licenseToBeAssigned.date}')`
      );
      licenseToBeAssigned.id = responseAssignment[0];
      licenseToBeAssigned = licenseToBeAssigned;
      // Insert in array
      licenseToBeAssigned.contractNumber = suscriptionSelected.label;
      licenseToBeAssigned.pictureURL = productsList.find((p) => {
        return (
          p.id ===
          suscriptionsList.find((s) => {
            return s.id === licenseToBeAssigned.suscription;
          }).product
        );
      }).pictureURL;
      licenseToBeAssigned.productName = productsList.find((p) => {
        return (
          p.id ===
          suscriptionsList.find((s) => {
            return s.id === licenseToBeAssigned.suscription;
          }).product
        );
      }).name;
      licenseToBeAssigned.username = keysList.find((k) => {
        return (
          k.id ===
          suscriptionsList.find((s) => {
            return s.id === licenseToBeAssigned.suscription;
          }).license
        );
      }).username;
      myLicenses.push(licenseToBeAssigned);
      myLicenses = myLicenses;
      assignLicenseMenu = false;
      emptyLicenseToBeAssigned();
      resetErrors();
      resetSelectedCombobox();
      loadPeopleLicenses();
    }
  };

  let deleteAssignment = async (pos) => {
    const assignment = myLicenses[pos];
    myLicenses.splice(pos, 1);
    myLicenses = myLicenses;
    await post(`DELETE FROM licenseAssignments WHERE id=${assignment.id}`);
    await loadPeopleLicenses();
    peopleLicenseX = [];
    peopleLicenseX = peopleLicenses.filter((p) => {
      return p.suscription === assignLicenseMenu;
    });
  };

  let toogleAssignLicenseMenu = () => {
    assignLicenseMenu = !assignLicenseMenu;
  };

  let emptyLicenseToBeAssigned = () => {
    licenseToBeAssigned = {
      id: "",
      contact: myId,
      suscription: "",
      project: "",
      date: moment().format("YYYY-MM-DD"),
    };
  };

  $: if (projectSelected) {
    errorProject = false;
  }

  $: if (companySelected) {
    errorCompany = false;
    loadProducts(companySelected.value);
    loadKeys(companySelected.value);
  }

  $: if (productSelected && keySelected) {
    errorProduct = false;
    errorKey = false;
    loadSuscriptions(productSelected.value, keySelected.value);
  }

  $: if (suscriptionSelected && projectSelected) {
    licenseToBeAssigned.suscription = suscriptionSelected.value;
    licenseToBeAssigned.project = projectSelected.value;
    licenseToBeAssigned = licenseToBeAssigned;
  }

  $: if (suscriptionSelected) {
    if (suscriptionSelected.value !== "") {
      peopleLicenseX = [];
      peopleLicenseX = peopleLicenses.filter((p) => {
        return p.suscription === suscriptionSelected.value;
      });
    }
  }

  $: if (projectId !== -1) {
    selectProject();
  }
</script>

<div class="dashboardContainer">
  <DashboardTitle title={boxTitle} numEvents={myLicenses.length} />
  <div class="dashboardBody customScrollbars">
    {#if !assignLicenseMenu}
      {#if myLicenses.length > 0}
        <div class="myLicenses">
          <span class="title">My licenses</span>
          <div class="myLicensesListContainer">
            {#each myLicenses as license, pos}
              <div class="myLicenseContainer">
                <div class="myLicenseProductImg">
                  <img src={license.pictureURL} alt="Product Img" />
                </div>
                <div class="myLicenseData">
                  <span>{license.productName}</span>
                  <span>{license.username}</span>
                </div>
                <div class="myLicenseButton">
                  <img
                    class="cancelImg"
                    src="images/x-red.svg"
                    alt="Menu Icon"
                    on:click={async () => {
                      await deleteAssignment(pos);
                    }}
                  />
                </div>
              </div>
            {/each}
          </div>
        </div>
      {/if}
      <div
        class="assignNewLicenseContainer {myLicenses.length === 0
          ? 'fullScreen'
          : ''}"
      >
      <!--
        <div class="button" on:click={() => toogleAssignLicenseMenu()}>
          <span>Assign License</span>
        </div>
        -->
      </div>
    {:else if assignLicenseMenu}
      <div class="addLicense">
        <span class="title">Assign license</span>
        <div class="separator">
          <span>Project</span>
          <div class="combobox">
            <Combobox
              bind:selectedValue={projectSelected}
              options={optionsProjects}
              backgroundColor="white"
              error={errorProject}
              disabled={projectId !== -1}
            />
          </div>
        </div>
        <div class="separator">
          <span>Company</span>
          <div class="combobox">
            <Combobox
              bind:selectedValue={companySelected}
              options={optionCompanies}
              backgroundColor="white"
              error={errorCompany}
            />
          </div>
        </div>
        <div class="separator">
          <span>Product</span>
          <div class="combobox">
            <Combobox
              bind:selectedValue={productSelected}
              options={optionProducts}
              backgroundColor="white"
              error={errorProduct}
            />
          </div>
        </div>
        <div class="separator">
          <span>Key</span>
          <div class="combobox">
            <Combobox
              bind:selectedValue={keySelected}
              options={optionKeys}
              backgroundColor="white"
              error={errorKey}
            />
          </div>
        </div>
        <div class="separator">
          <span>Suscription</span>
          <div class="combobox">
            <Combobox
              bind:selectedValue={suscriptionSelected}
              options={optionSuscriptions}
              backgroundColor="white"
            />
          </div>
        </div>
        {#if suscriptionSelected && suscriptionSelected.value}
          <div class="licenseUsedByContainer">
            <span class="title"
              >License used by ({peopleLicenseX.length}/{suscriptionSelected.seats})</span
            >
            <div class="peopleListContainer">
              {#each peopleLicenseX as p}
                <div class="contactPic">
                  <Picture
                    image={p.pic}
                    name={p.username}
                    lastActivity={p.lastActivity}
                    officeStatus={p.officeStatus}
                    atHome={p.atHome}
                    small={true}
                    showHint={true}
                  />
                </div>
              {/each}
              {#each Array(suscriptionSelected.seats - peopleLicenseX.length) as free}
                <div class="freeSeatContainer">
                  <img
                    class="addImg"
                    src="/images/plus.svg"
                    alt="Asign License"
                    on:click={() => {
                      validateLicenseAssignment();
                    }}
                  />
                </div>
              {/each}
            </div>
          </div>
        {/if}
        <div class="buttonsContainer">
          <div
            class="button"
            on:click={() => {
              resetErrors();
              resetSelectedCombobox();
              assignLicenseMenu = false;
            }}
          >
            <span>Cancel</span>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  div.dashboardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  div.dashboardBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    padding: 0 15px 0 15px;
    overflow: overlay;
  }

  div.addLicense {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 8px 0 8px 0;
  }

  div.separator {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
  }

  div.separator span {
    font-size: 12px;
    color: var(--labit-card-gray);
  }

  div.separator div.combobox {
    height: 17px;
  }

  div.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--fullcalendar-header-monthlycalendar);
    border-radius: 15px;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
  }

  .button span {
    margin: 0 10px;
  }

  div.myLicenses {
    margin: 15px 0 5px 0;
    flex-direction: column;
  }

  .myLicenseButton img.cancelImg {
    height: 10px;
    width: 10px;
    cursor: pointer;
    margin-left: 5px;
  }

  .myLicensesListContainer {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .myLicenseContainer {
    display: flex;
    align-items: center;
    margin: 3px 0;
    flex-direction: row;
    width: 100%;
    padding-right: 5px;
    background-color: var(--labit-background-gray);
    border-radius: 12px;
    padding: 8px;
  }

  span.title {
    font-size: 13px;
    font-weight: bold;
    color: var(--labit-card-gray);
    width: 100%;
    margin-bottom: 5px;
  }

  div.myLicenses div.contact span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    color: var(--labit-card-gray);
    /* border-top: 1px solid #d7e1ea; */
  }

  .assignNewLicenseContainer,
  .myLicenses {
    /*height: 50%;*/
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .assignNewLicenseContainer {
    margin-bottom: 20px;
  }

  .myLicenses {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .fullScreen {
    height: 100%;
  }

  .buttonsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    margin-top: 5px;
  }

  .licenseUsedByContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
  }

  .peopleListContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .contactPic,
  .freeSeatContainer {
    width: 40px;
    height: 40px;
  }

  .freeSeatContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addImg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .contactPic,
  .freeSeatContainer {
    margin: 3px;
  }

  .freeSeatContainer {
    border-radius: 50%;
  }

  .myLicenseProductImg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 5px;
  }

  .myLicenseProductImg img {
    width: 30px;
    max-height: 30px;
  }

  .myLicenseData {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .myLicenseData span {
    font-size: 13px;
  }
</style>
