<script>
    import { onDestroy } from 'svelte';

    import TaskItem from './TaskItem.svelte'
    import DropdownSelector from './DropdownSelector.svelte';
    import InputText from './InputText.svelte';
    import InputTime from './InputTime.svelte';
    import BtnIconsArray from './BtnIconsArray.svelte';
    import BarChart from './BarChart.svelte';
    //import CircularProgress from './CircularProgress.svelte';
    import HorizontalProgressBar from './HorizontalLineProgressBarChart.svelte';
    import WeekTimeCalculator from './WeekTimeCalculator.svelte';
    import RangeDatePicker from '../factorial/RangeDatePicker.svelte';
    import ToggleableContainer from '../../monitor/timeReports/ToggleableContainer.svelte';

    import moment from 'moment';

    import { addTask, getTasks, getTaskTypes, getMyProjects, modifyTask, copyTask, deleteTask, getMillisFromTasks, fillDatesArr, getWeek, getNumberOfWorkedDays } from './clockifyData';
    import { longMsToTime, msToTime, timeConvert } from '../../../js/formatDateTime';
    import { taskTimeStart, task } from "../../../js/stores";
    import { getUserId, getRole } from '../../../js/localInfo';
    import { openURL } from '../../../js/openURL';
    import { post } from '../../../js/labit-connection';

    export let role;
    export let id;
    
    const myId = getUserId();
    const myRole = getRole();
    const observer = id !== myId;
    
    if (observer && myRole !== 'Admin') openURL('/');

    const today = moment();
    
    let timerOpen = false;

    let week = {};
    let tasksToday = { startDate: undefined, endDate: undefined, tasks: [] };
    let chartTasks = {};
    let allYearTasks;

    let description = '';
    let [selectedProject, selectedPhase, selectedTaskType] = ['', '', ''];
    let [strStart, strEnd] = ['', ''];
    let [taskTypeObj, taskTypeSeparated] = [{}, {}];
    let [projects, phaseObj, phaseAndProjectObj] = [[], {}, {}];
    let chart = { start: moment().startOf('isoWeek'), end: moment().endOf('isoWeek') };
    let chartDaysNumb = 7;
    let otherTasks = {};
    let otherTasksKeys = [];

    let timesInRange = { total: '00:00', billable: '00:00', /* launch: '00:00', */ nonBillable: '00:00' };

    let bag;
    let bagTime = { week: 0, month: 0, trimester: 0, year: 0 };

    let error = {
        description: false,
        phase: false,
        taskType: false,
        start: false,
        end: false
    }

    let weekWorkedSeconds = -1;

    const toggleTimer = () => ( timerOpen = !timerOpen );
    
    onDestroy(() => {
        task.set( { description: description, project: selectedProject, phase: selectedPhase, taskType: selectedTaskType, start: strStart, end: strEnd } );
    });

    const playChrono = () => {
        if ($taskTimeStart === 0) {
            const now = Date.now();
            taskTimeStart.set(now);
            strStart = longMsToTime(now);
            strEnd = '';
        } else {
            const now = Date.now();
            strEnd = longMsToTime(now);
            taskTimeStart.set(0);
        }
        toggleTimer();
    }

    const addTaskBtn = async () => { 
        const taskAdded = await addTask(description, selectedPhase, selectedTaskType, strStart, strEnd, id);
        if (taskAdded.type === 'error') {
            error = taskAdded.error;
        } else {
            await getAllTasks();
            error = { description: false, phase: false, taskType: false, start: false, end: false };
        }
    }

    const addLaunchTask = async () => {
        const taskAdded = await addTask('Lunch time', 663, 119, "14:00", "14:30", id);
        if (taskAdded.type === 'error') {
            error = taskAdded.error;
        } else {
            await getAllTasks();
        }
    }

    const addRoundOffTask = async () => {
        const taskAdded = await addTask('Round off', 663, 118, "14:30", "14:45", id);
        if (taskAdded.type === 'error') {
            error = taskAdded.error;
        } else {
            await getAllTasks();
        }
    }

    // Llama muchas veces al back para hacer inserciones y borrados
    const addTemplate = async (day) => {
        const todayStr = today.format('DD/MM/YYYY');
        const todayTasks = allYearTasks[todayStr].tasks;
        const dayToCloneTasks = allYearTasks[day].tasks;

        let promiseArr = [];
        todayTasks.forEach(task => {
            const promise = deleteTask(task.id);
            promiseArr.push(promise);
        });

        dayToCloneTasks.forEach(task => {
            const start = moment(task.start, 'x').format('HH:mm');
            const end = moment(task.end, 'x').format('HH:mm');
            const promise = addTask(task.description, task.phase_id, task.task_type_id, start, end, id);
            promiseArr.push(promise);
        });

        await Promise.all(promiseArr);
    }

    /* BOLSA DE HORAS TENIENDO EN CUENTA LA NUEVA ENTIDAD DE LA BASE DE DATOS 
    QUE ALMACENA CUANDO SE HIZO EL CAMBIO DE HORAS Y LAS NUEVAS HORAS */
    
    const accumulatedWeekHours = async () => {
        const today = moment();
        const freeDays = 5 - parseInt(week.nWorkingDays);
        const weekStart = moment().startOf('week');
        
        const workTimeHistory = await getUserWorkTimeHistory(id);
        
        const workMinutesByDay = parseInt(week.duration * 60) / parseInt(week.nWorkingDays);
        
        const numberOfWorkedDaysInWeek = await getNumberOfWorkedDays(weekStart,today,id);
                
        let total;

        if(workTimeHistory.length > 0){
            // Calculamos el total de horas al día si la entidad worktimeHistory tiene datos
            const workMinutesByDayRedux = parseInt(workTimeHistory[0].weekWorkTime * 60) / parseInt(week.nWorkingDays);
            
            const weekMillis = getMillisFromTasks(weekStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDayRedux);
            const expectedWorkedMillisWeek = numberOfWorkedDaysInWeek * workMinutesByDayRedux * 60000;
            
            total = (weekMillis.billable - expectedWorkedMillisWeek) / 60000;
        }else{
            const weekMillis = getMillisFromTasks(weekStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDay);
            const expectedWorkedMillisWeek = numberOfWorkedDaysInWeek * workMinutesByDay * 60000;
         
            total = (weekMillis.billable - expectedWorkedMillisWeek) / 60000;
        }
        return total;
    }

    const accumulatedMonthHours = async () => {
        const today = moment();
        const workMinutesByDay = parseInt(week.duration * 60) / parseInt(week.nWorkingDays);
        const freeDays = 5 - parseInt(week.nWorkingDays);

        const workTimeHistory = await getUserWorkTimeHistory(id);

        let monthStart = moment().startOf('month');

        // Si el primer día del mes no es lunes (1), retroceder hasta el lunes más cercano
        if (monthStart.day() !== 1) {
            monthStart = monthStart.clone().subtract(monthStart.day() - 1, 'days');
        }
        
        const numberOfWorkedDaysInMonth = await getNumberOfWorkedDays(monthStart,today,id);

        let total;

        if(workTimeHistory.length > 0){
            const workMinutesByDayRedux = parseInt(workTimeHistory[0].weekWorkTime * 60) / parseInt(week.nWorkingDays);

            const monthMillis = getMillisFromTasks(monthStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDayRedux);
            const expectedWorkedMillisMonth = numberOfWorkedDaysInMonth * workMinutesByDayRedux * 60000;

            total = (monthMillis.billable - expectedWorkedMillisMonth) / 60000;
        }else{
            const monthMillis = getMillisFromTasks(monthStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDay);
            const expectedWorkedMillisMonth = numberOfWorkedDaysInMonth * workMinutesByDay * 60000;
            
            total = (monthMillis.billable - expectedWorkedMillisMonth) / 60000;
        }
        return total;
    }

    const accumulatedYearHours = async () => {
        const today = moment();
        const freeDays = 5 - parseInt(week.nWorkingDays);
        const workMinutesByDay = parseInt(week.duration * 60) / parseInt(week.nWorkingDays);
        
        let bagStart = moment().startOf('year');
        const workTimeHistory = await getUserWorkTimeHistory(id);
        
        let dayBefore, workMinutesByDayRedux, weekDistributionOldFinal, total;

        // Solo calculamos dayBefore si workTimeHistory tiene elementos.
        if (workTimeHistory.length > 0) {
            
            workMinutesByDayRedux = parseInt(workTimeHistory[0].weekWorkTime * 60) / parseInt(week.nWorkingDays);
            const startDateRedux = workTimeHistory.map(data => moment(data.start_date));

            const weekDistributionOld = workTimeHistory[0].weekWorkDistributionOld;
            const weekDistributionOldFormat = weekDistributionOld.split("|");
            
            weekDistributionOldFinal = weekDistributionOldFormat.reduce((acc, curr, index) => {
                acc[index] = curr;
                return acc;
            }, {});
            weekDistributionOldFinal[5] = "0";
            weekDistributionOldFinal[6] = "0";

            dayBefore = startDateRedux[0].clone().subtract(1, 'months').endOf("month");

            const numberOfWorkedDaysInYear = await getNumberOfWorkedDays(bagStart, dayBefore, id);
            const expectedWorkedMillisYear = numberOfWorkedDaysInYear * workMinutesByDay * 60000;

            const numberOfWorkedDaysInYearRedux = await getNumberOfWorkedDays(startDateRedux[0], today, id);
            const expectedWorkedMillisYearRedux = numberOfWorkedDaysInYearRedux * workMinutesByDayRedux * 60000;

            const yearMillis = getMillisFromTasks(bagStart, dayBefore, allYearTasks, weekDistributionOldFinal, freeDays, workMinutesByDay);
            const yearMillisRedux = getMillisFromTasks(startDateRedux[0], today, allYearTasks, week.distribution, freeDays, workMinutesByDayRedux);

            total = ((yearMillis.billable - expectedWorkedMillisYear) / 60000) + ((yearMillisRedux.billable - expectedWorkedMillisYearRedux) / 60000);
       
        } else {
            // En el caso de que no haya workTimeHistory, usamos directamente el rango desde bagStart hasta today.
            const numberOfWorkedDaysInYear = await getNumberOfWorkedDays(bagStart, today, id);
            const expectedWorkedMillisYear = numberOfWorkedDaysInYear * workMinutesByDay * 60000;
            const yearMillis = getMillisFromTasks(bagStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDay);

            total = (yearMillis.billable - expectedWorkedMillisYear) / 60000;
        }
        return total;
    };

    const accumulatedHours = async () => {
        const today = moment();
        const freeDays = 5 - parseInt(week.nWorkingDays);
        const workMinutesByDay = parseInt(week.duration * 60) / parseInt(week.nWorkingDays);
        
        const hired = moment(bag.hired, 'YYYY-MM-DD HH:mm:ss');
        const bagLastUpdate = moment(bag.bagMinutesLastUpdate, 'YYYY-MM-DD HH:mm:ss');
        
        const workTimeHistory = await getUserWorkTimeHistory(id);
        
        // const bagStart = (hired.isValid() && bagLastUpdate.isValid()) ? 

        let bagStart;
        if (hired.isValid() && bagLastUpdate.isValid()) bagStart = bagLastUpdate.isSameOrAfter(hired) ? bagLastUpdate : hired;
        else if (hired.isValid()) bagStart = hired;
        else if (bagLastUpdate.isValid()) bagStart = bagLastUpdate;
        else bagStart = moment().startOf('year');
    
        // bagStart = bagStart.isSameOrAfter(yearStart) ? bagStart : yearStart;
        const bagMinutes = bag.bagMinutes === null ? 0 : parseInt(bag.bagMinutes);

        let dayBefore, workMinutesByDayRedux, weekDistributionOldFinal, total;

        if(workTimeHistory.length > 0){
            workMinutesByDayRedux = parseInt(workTimeHistory[0].weekWorkTime * 60) / parseInt(week.nWorkingDays);

            const startDateRedux = workTimeHistory.map(data => moment(data.start_date));

            const weekDistributionOld = workTimeHistory[0].weekWorkDistributionOld;
            const weekDistributionOldFormat = weekDistributionOld.split("|");
            
            weekDistributionOldFinal = weekDistributionOldFormat.reduce((acc, curr, index) => {
                acc[index] = curr;
                return acc;
            }, {});
            weekDistributionOldFinal[5] = "0";
            weekDistributionOldFinal[6] = "0";

            dayBefore = startDateRedux[0].clone().subtract(1, 'months').endOf("month");

            const numberOfWorkedDaysInYear = await getNumberOfWorkedDays(bagStart, dayBefore, id);
            const expectedWorkedMillisYear = numberOfWorkedDaysInYear * workMinutesByDay * 60000;

            const numberOfWorkedDaysInYearRedux = await getNumberOfWorkedDays(startDateRedux[0], today, id);
            const expectedWorkedMillisYearRedux = numberOfWorkedDaysInYearRedux * workMinutesByDayRedux * 60000;

            const yearMillis = getMillisFromTasks(bagStart, dayBefore, allYearTasks, weekDistributionOldFinal, freeDays, workMinutesByDay);
            const yearMillisRedux = getMillisFromTasks(startDateRedux[0], today, allYearTasks, week.distribution, freeDays, workMinutesByDayRedux);

            total = ((yearMillis.billable - expectedWorkedMillisYear) / 60000) + ((yearMillisRedux.billable - expectedWorkedMillisYearRedux) / 60000) + bagMinutes;
      
        }else{
            const numberOfWorkedDaysInYear = await getNumberOfWorkedDays(bagStart, today, id);
            const yearMillis = getMillisFromTasks(bagStart, today, allYearTasks, week.distribution, freeDays, workMinutesByDay);
            const expectedWorkedMillisYear = numberOfWorkedDaysInYear * workMinutesByDay * 60000;
            
            total = ((yearMillis.billable - expectedWorkedMillisYear) / 60000) + bagMinutes;
        }
        return total;
    }

    /* LLamada BBDD */

    const getUserWorkTimeHistory = async (user_id) => {
        const response = await post(`SELECT * FROM user_worktime_history WHERE user_id = ${user_id}`);
        return response;
    }

    const getBagTime = async () => {
       const weeklyExtraMinutes = await accumulatedWeekHours();
       const monthExtraMinutes = await accumulatedMonthHours();
       const yearExtraMinutes = await accumulatedYearHours();
       const accumulatedExtraMinutes = await accumulatedHours();

        bagTime = { 
            week: weeklyExtraMinutes,
            month: monthExtraMinutes,
            trimester: 0, 
            year: yearExtraMinutes,
            accumulated: accumulatedExtraMinutes,
        };
    } 

    const tasksDaysBefore = (start, end) => {
        const daysArr = fillDatesArr(start, end, 'moment', 'DD/MM/YYYY').reverse();
        otherTasks = daysArr.reduce((acc, day) => {
            if (allYearTasks.hasOwnProperty(day)) {
                acc[day] = allYearTasks[day];
            }
            return acc;
        }, {});
        otherTasksKeys = Object.keys(otherTasks);
    }

    const getRangeTotalTime = () => {
        const { start, end } = chart;
        const res = getMillisFromTasks(start, end, allYearTasks, week.distribution);
        timesInRange = { 
            total: timeConvert(res.total/60000),
            billable: timeConvert(res.billable/60000),
            nonBillable: timeConvert(res.nonBillable/60000)
        }
    }

    const getAllTasks = async () => {
        let [from, to] = [moment().subtract(2,'years').startOf('year'), moment().endOf('year')];
        allYearTasks = await getTasks(from, to, id);

        getChartTasks(chart.start, chart.end);
        tasksToday = allYearTasks[moment().format('DD/MM/YYYY')]
        tasksDaysBefore(chart.start, chart.end);
        getRangeTotalTime();
        getBagTime();

        const allWeekMillis = getMillisFromTasks(moment().startOf('week'), moment().endOf('week'), allYearTasks, week.distribution);
        weekWorkedSeconds = allWeekMillis.billable / 1000;
    }

    const getChartTasks = (from, to) => {
        const days = fillDatesArr(from, to, 'moment', 'DD/MM/YYYY');
        if (allYearTasks !== undefined) {
            chartTasks = days.reduce((acc, day) => {
                if (allYearTasks.hasOwnProperty(day)) {
                    acc[day] = allYearTasks[day];
                }
                return acc;
            }, {});
        }
    }

    const getOrdinalSuffixOf = (i) => {
        const j = parseInt(i) % 10,
            k = parseInt(i) % 100;
        if (j === 1 && k !== 11) return "st";
        if (j === 2 && k !== 12) return "nd";
        if (j === 3 && k !== 13) return "rd";
        return "th";
    }

    const setStartDay = async () => {
        if (tasksToday.startDate === undefined || tasksToday.startDate === null) {
            const now = moment();
            const [myId] = await post(`INSERT INTO startEndDay (start, user_id) values ("${now.format('YYYY-MM-DD HH:mm:ss')}", ${id});`);
            tasksToday.startDate = now;
            tasksToday.startEndDateId = myId;
        }
    }

    const setEndDay = async () => {
        if (tasksToday.startDate !== undefined && tasksToday.startDate !== null) {
            const now = moment();
            await post(`UPDATE startEndDay SET end = "${now.format('YYYY-MM-DD HH:mm:ss')}" WHERE id = ${tasksToday.startEndDateId};`);
            tasksToday.endDate = now;
            await getAllTasks();
        }
    }

    const changeChartDate = (method, days) => {
        if (method === 'add') {
            chart.start = chart.start.add(days, 'days');
            chart.end = chart.end.add(days, 'days');
        } else if (method === 'subtract') {
            chart.start = chart.start.subtract(days, 'days');
            chart.end = chart.end.subtract(days, 'days');
        }
        showChartsData();
    }

    const showChartsData = () => {
        getChartTasks(chart.start, chart.end);
        tasksDaysBefore(chart.start, chart.end);
        chartDaysNumb = chart.end.diff(chart.start, 'days') + 1;
        getRangeTotalTime();
    }
    
    (async () => {
        const weekPromise = getWeek(id);
        const taskTypesPromise = getTaskTypes();
        const getMyProjectsPromise = getMyProjects(role, id);
        const bagTimeExtraPromise = post(`SELECT hired, bagMinutesLastUpdate, bagMinutes FROM people WHERE contact_id = ${id}`);
        
        [
            week, 
            [taskTypeObj, taskTypeSeparated], 
            [projects, phaseObj, phaseAndProjectObj],
            [bag]
        ] = await Promise.all([
            weekPromise, 
            taskTypesPromise, 
            getMyProjectsPromise,
            bagTimeExtraPromise
        ]);
        
        await getAllTasks();
        showChartsData();

        const allWeekMillis = getMillisFromTasks(moment().startOf('week'), moment().endOf('week'), allYearTasks, week.distribution);
        weekWorkedSeconds = allWeekMillis.billable / 1000;

        //Stores, para recuperar info
        timerOpen = $taskTimeStart !== 0;
        const t = $task;
        if (t.description !== '') description = t.description;
        if (t.project !== '') selectedProject = t.project;
        if (t.phase !== '') selectedPhase = t.phase;
        if (t.taskType !== '') selectedTaskType = t.taskType;
        if (t.start !== '') strStart = t.start;
        if (t.end !== '') strEnd = t.end;
    })();

    $: phases = phaseObj[selectedProject] || [{ id: '', value: '🙄', type: 'emoji' }];

    let taskTypesOptions = [{ id: '', value: '🙄', type: 'emoji' }];
    $: if (selectedPhase !== '0') {
        taskTypesOptions = selectedPhase === '663' ? taskTypeSeparated['nonBillable'] : taskTypeSeparated['billable'];
    }

</script>

<div class="clockifyContainer flex items-center col">
    {#if !observer}
    <div class="flex items-center row startEndDiv">
        <button disabled={(tasksToday.startDate !== null && tasksToday.startDate !== undefined)} on:click={setStartDay}>🌝 {(tasksToday.startDate === null || tasksToday.startDate === undefined) ? 'Hello!' : tasksToday.startDate.format('HH:mm:ss')} </button>
        <button on:click={setEndDay}>🌚 {(tasksToday.endDate === null || tasksToday.endDate === undefined) ? 'Bye!' : tasksToday.endDate.format('HH:mm:ss')}</button>
    </div>
    <div id="insertDataContainer" class="flex items-center row w100">
        <div id="task">
            <DropdownSelector defaultText='Project' options={projects} bind:selectedOption={selectedProject} error={error.phase} />
            <DropdownSelector defaultText='Phase' options={phases} bind:selectedOption={selectedPhase} error={error.phase} />
            <DropdownSelector defaultText='Task type' options={taskTypesOptions} bind:selectedOption={selectedTaskType} error={error.taskType} />
        
            <InputText placeholder='Description...' bind:text={description} error={error.description} />
        </div>
        <div id="timer">
            <InputTime bind:time={strStart} placeholder='Start...' error={error.start} />
            <span>to</span>
            <InputTime bind:time={strEnd} placeholder='End...' error={error.end} />
        </div>
        <BtnIconsArray images={[timerOpen ? "/images/new/pause.svg" : "/images/new/play.svg", "/images/new/new.svg"]} clickEvents={[ playChrono, addTaskBtn ]}/>
    </div>
    {/if}
    <div class="todayDataContainer flex items-center col w100">
        <div class="w100 flex items-center space-between" style="padding: 12px 0">
            <div class="todayTitleContainer flex items-center row space-between">
                <span class="big flex items-center" style="align-items: start;">{today.format('DD')} <sup>{getOrdinalSuffixOf(today.format('DD'))}</sup> {today.format('MMMM')}</span>
                <span class="big">{today.format('dddd')}</span>
                <div class="totalTimeByDay flex items-center space-between">
                    <img src="/images/new/watch.svg" alt="Watch">
                    <span class="big">{ timeConvert((tasksToday.billableMillis + tasksToday.nonBillableMillis) / 60000) }h</span>
                    <span>{ timeConvert((tasksToday.billableMillis - tasksToday.roundOffMs) > 0 ? (tasksToday.billableMillis - tasksToday.roundOffMs) / 60000 : 0 )}h</span>
                    <span>{ timeConvert((tasksToday.nonBillableMillis + tasksToday.roundOffMs) / 60000) }h</span>
                </div>
                <div class="start-end-container">
                    <span>{(tasksToday.startDate === undefined || tasksToday.startDate === null) ? '' : tasksToday.startDate.format('HH:mm')} - {(tasksToday.endDate === undefined || tasksToday.endDate === null) ? '' : tasksToday.endDate.format('HH:mm')}</span>
                </div>
            </div>
            {#if !observer}
            <div class="flex items-center">
                <BtnIconsArray images={["/images/new/lunch.svg", "/images/new/timeoff.svg"]} clickEvents={[addLaunchTask, addRoundOffTask]}/>
            </div>
            {/if}
        </div>
        {#key tasksToday}
        <div id="tasksContainer">
            {#each tasksToday.tasks as {id, description, phase_id, task_type_id, start, end} (id)}
            <TaskItem
                {id}
                taskDescription={ description }
                taskProject={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].projName : `Phase id: ${phase_id}` }
                taskPhase={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].phaseName : `Phase id: ${phase_id}` }
                taskType={taskTypeObj[task_type_id]}
                startMillis={ start }
                endMillis={ end }
                modificable={ !observer }
                on:dispatchModify={ async (ev) => { await modifyTask(ev); await getAllTasks(); }}
                on:dispatchCopy={ async (ev) => { await copyTask(ev); await getAllTasks(); }}
                on:dispatchDelete={ async (ev) => { await deleteTask(ev.detail.id); await getAllTasks(); }}
            />
            {/each}
        </div>
        {/key}
    </div>
</div>
<div id="chartsContainer" class="flex items-center row w100 space-between">
    <div class="flex items-center col">
        <div class="flex items-center row w100 space-between">
            <div class="flex items-center">
            <BtnIconsArray images={[
                "/images/new/week.svg",
                "/images/new/month.svg"
            ]} clickEvents={[
                () => { chart.start = moment().startOf('isoWeek'); chart.end = moment().endOf('isoWeek'); showChartsData(); },
                () => { chart.start = moment().startOf('month'); chart.end = moment().endOf('month'); showChartsData(); }
            ]}/>
            </div>

            <div class="flex items-center">
                <BtnIconsArray images={[
                    "/images/new/arrow-left.svg", 
                    "/images/new/arrow-right.svg"
                ]} clickEvents={[
                    () => changeChartDate('subtract', chartDaysNumb),
                    () => changeChartDate('add', chartDaysNumb)
                ]}/>

                {#key chart}
                <RangeDatePicker returnFormat={'moment'} disableBeforeToday={false} start={chart.start} end={chart.end} on:close={ (ev) => { chart = ev.detail; showChartsData(); } }/>
                {/key}
            </div>
        </div>
        <BarChart tasks={chartTasks} predefinedTimes={week.distribution}/>
    </div>
    <div class="flex items-center col space-evenly">
        {#key weekWorkedSeconds}
        {#if week.predefinedTimes !== undefined && weekWorkedSeconds > -1}
        <div id="time-reporter-container" class="container flex items-center col evenly">
            <div class="title items-center flex">
                <img src="/images/new/watch.svg" alt="">
                <span>Time</span>
            </div>
            <div class="content flex items-center between">
                <div class="pad flex items-center col data text-center between">
                    <span class="title">Hours</span>
                    <span class="time">{timesInRange.total}h</span>
                </div>
                <div class="pad group border flex items-center between">
                    <div class="flex items-center col data text-center between">
                        <span class="title">Work</span>
                        <span class="blue time">{timesInRange.billable}h</span>
                    </div>
                    <div class="flex items-center col data text-center between">
                        <span class="title">Round off</span>
                        <span class="time">{timesInRange.nonBillable}h</span>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="week-calculator-container" class="container flex items-center col evenly">
            <div class="title items-center flex">
                <img src="/images/new/calculator-dark-blue.svg" alt="">
                <span>Maths</span>
            </div>
            <div class="w100">
                <HorizontalProgressBar predefinedTimes={week.predefinedTimes} currentBillableSeconds={weekWorkedSeconds} />
                <WeekTimeCalculator predefinedTimes={week.predefinedTimes} />
            </div>
        </div>
    
        <div id="extra-time-container" class="container flex items-center col evenly">
            <div class="title items-center flex">
                <img src="/images/new/baghour-dark-blue.svg" alt="">
                <span>Bolsa</span>
            </div>
            <div class="content flex items-center between">
                <div class="flex items-center col data text-center between">
                    <span class="{bagTime.week < 0 ? 'red' : 'yellow'} time border pad">{timeConvert(bagTime.week)}h</span>
                    <span class="title">Week</span>
                </div>
                <div class="flex items-center col data text-center between">
                    <span class="{bagTime.month < 0 ? 'red' : 'yellow'} time border pad">{timeConvert(bagTime.month)}h</span>
                    <span class="title">Month</span>
                </div>
                <!-- <div class="flex items-center col data text-center between">
                    <span class="yellow time border pad">Coming soon</span>
                    <span class="title">Trimester</span>
                </div> -->
                <div class="flex items-center col data text-center between">
                    <span class="{bagTime.year < 0 ? 'red' : 'yellow'} time border pad">{timeConvert(bagTime.year)}h</span>
                    <span class="title">Year</span>
                </div>

                <div class="flex items-center col data text-center between">
                    <span class="{bagTime.accumulated < 0 ? 'red' : 'yellow'} time border pad">{timeConvert(bagTime.accumulated)}h</span>
                    <span class="title">Accumulated</span>
                </div>
            </div>
        </div>
        {/if}
        {/key}
    </div>
</div>
<div id="previousDaysContainer">
    {#if otherTasksKeys.length > 0}
    {#each otherTasksKeys as day (day)}
    {@const totalMillis = otherTasks[day].billableMillis + otherTasks[day].nonBillableMillis}
    {@const billable = otherTasks[day].billableMillis - otherTasks[day].roundOffMs}
    {@const nonBillable = otherTasks[day].nonBillableMillis + otherTasks[day].roundOffMs}
    {@const date = moment(day, 'DD/MM/YYYY')}

    {#if otherTasks.hasOwnProperty(day)}
        <ToggleableContainer --bgColor="#fbfbfb">
            <div class="toggleable-header" slot="header">
                <div class="todayTitleContainer flex items-center row space-between">
                    <span class="big flex items-center" style="align-items: start; width: 110px;">{date.format('DD')} <sup>{getOrdinalSuffixOf(date.format('DD'))}</sup> {date.format('MMMM')}</span>
                    <span class="big">{date.format('dddd')}</span>
                    <div class="totalTimeByDay flex items-center space-between">
                        <img src="/images/new/watch.svg" alt="Watch">
                        <span class="big">{ msToTime(totalMillis) }h</span>
                        <span>{ msToTime(billable) }h</span>
                        <span>{ msToTime(nonBillable) }h</span>
                    </div>
                    <div class="start-end-container">
                        <span>{(otherTasks[day].startDate === undefined || otherTasks[day].startDate === null) ? '' : otherTasks[day].startDate.format('HH:mm')} - {(otherTasks[day].endDate === undefined || otherTasks[day].endDate === null) ? '' : otherTasks[day].endDate.format('HH:mm')}</span>
                    </div>
                </div>
                {#if !observer}
                <div class="flex items-center">
                    <BtnIconsArray images={["/images/new/template.svg"]} clickEvents={[ async () => { await addTemplate(day); await getAllTasks(); } ]}/>
                </div>
                {/if}
            </div>

            <div class="body-main" slot="body">
            {#if observer}
            {#each otherTasks[day].tasks as {id, description, phase_id, task_type_id, start, end} (id)}
            <TaskItem
                {id}
                taskDescription={ description }
                taskProject={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].projName : `Phase id: ${phase_id}` }
                taskPhase={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].phaseName : `Phase id: ${phase_id}` }
                taskType={taskTypeObj[task_type_id]}
                startMillis={ start }
                endMillis={ end }
                modificable={ true }
                selectedDay={ moment(start, 'x') }
                on:dispatchModify={ async (ev) => { await modifyTask(ev); await getAllTasks(); }}
                on:dispatchCopy={ async (ev) => { await copyTask(ev); await getAllTasks(); }}
                on:dispatchDelete={ async (ev) => { await deleteTask(ev.detail.id); await getAllTasks(); }}
            />
            {/each}
            {:else}
            {#each otherTasks[day].tasks as {id, description, phase_id, task_type_id, start, end} (id)}
            <TaskItem
                {id}
                taskDescription={ description }
                taskProject={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].projName : `Phase id: ${phase_id}` }
                taskPhase={ phaseAndProjectObj.hasOwnProperty(phase_id) ? phaseAndProjectObj[phase_id].phaseName : `Phase id: ${phase_id}` }
                taskType={taskTypeObj[task_type_id]}
                startMillis={ start }
                endMillis={ end }
                observer={false}
            />
            {/each}
            {/if}
            </div>
        </ToggleableContainer>
    {/if}

    {/each}
    {/if}
</div>

<style>

    .flex {
        display: flex;
    }
    .col {
        flex-direction: column;
    }
    .between {
        justify-content: space-between;
    }
    .evenly {
        justify-content: space-evenly;
    }
    .text-center {
        text-align: center;
    }
    .items-center {
        align-items: center;
    }
    .content {
        height: 52px;
    }
    .content .title {
        color: rgb(174, 174, 174);
        font-size: 10px;
    }
    .container {
        flex-grow: 1;
        width: 100%;
        align-items: normal;
    }
    .container > .title {
        font-weight: bold;
    }
    .container > .title > img {
        width: 20px;
    }
    .group { 
        width: 200px;
    }
    .border {
        border: 1px solid #d7d7d7;
        border-radius: 40px;
    }
    .pad {
        padding: 4px 20px;
    }
    #extra-time-container .data .time {
        width: 140px;
    }
    .time {
        color: gray;
        font-size: 1.4rem;
    }
    #week-calculator-container {
        flex-grow: 0;
    }
    #time-reporter-container .content {
        width: 50%;
    }
    .yellow {
        color: #ffcd77;
    }
    .red {
        color: #ff6868;
    }
    .blue {
        color: #669eff;
    }
    .title span {
        margin-left: 8px;
    }

    .space-evenly {
        justify-content: space-evenly;
    }

    .toggleable-header {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
    }

    #tasksContainer {
        overflow-y: auto;
    }

    div.start-end-container {
        border: 1px solid #ededed;
        padding: 4px 12px;
        border-radius: 19px;
        width: 120px;
        text-align: center;
    }

    div.clockifyContainer, div#previousDaysContainer, div#chartsContainer {
        background-color: white;
        color: #35425B !important;
        border-radius: 0 0 19px 19px;
        padding: 20px 32px;
    }

    div#insertDataContainer {
        height: 28px;
        margin: 20px 0 15px 0;
        display: flex;
        justify-content: space-between;
    }
    
    div#task, 
    div#timer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
    }

    div#task {
        flex-grow: 1;
        padding-left: 0;
    }

    div#timer {
        width: 248px;
    }

    #timer > span {
        padding: 0 12px;
    }

    div#previousDaysContainer, div#chartsContainer {
        border-radius: 19px;
        margin: 8px 0;
    }

    div#previousDaysContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    div.todayDataContainer {
        height: 240px;
        padding-bottom: 20px;
        align-items: normal;
    }

    div.todayTitleContainer {
        width: 900px;
    }

    div.totalTimeByDay {
        width: 280px;
        background-color: #eef4fb;
        border-radius: 16px;
        padding: 2px 12px;
    }

    div#chartsContainer {
        height: 440px;
        align-items: normal;
    }

    div#chartsContainer > div {
        width: 48%;
    }

    .startEndDiv {
        gap: 16px;
        margin: 0 0 10px 0;
        align-self: start;
    }

    .startEndDiv button {
        width: 130px;
        border-radius: 19px;
        margin: 0 !important;
        background-color: #f0f4f8;
        cursor: pointer;
        border: none;
        padding: 1em;
    }

    .startEndDiv button:active {
        background-color: #e9e9e9!important;
    }

    .space-between {
        justify-content: space-between;
    }

    .big {
        font-size: 20px;
        font-weight: bolder;
    }

    .row {
        flex-direction: row;
    }

    .col {
        flex-direction: column;
    }

    .w100 {
        width: 100%;
    }

    sup {
        vertical-align: super;
        font-size: small;
    }
</style>