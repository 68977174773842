//export const LABIT_SERVER = "http://192.168.1.44:3000/dynamic";
export const DOMAIN = "https://skylab.labit.es/";
export const LABIT_SERVER = "https://skylab.labit.es/simplerest/rest2.php";
//export const LABIT_PICTURE_SERVER = "http://192.168.1.44:8887/people_pics";
export const LABIT_PICTURE_SERVER = "https://skylab.labit.es/people_pics";
export const LABIT_RECOVERY_PASSWORD_SERVER =
  "https://skylab.labit.es/rest/Password-recovery.php";
export const LABIT_REST_SERVER = "https://skylab.labit.es/rest/";
export const LABIT_PUBLIC_FOLDER = "https://skylab.labit.es/uploads";
const DEBUG = false;

import { getToken } from "./localInfo";

export let post = async (query) => {
  const token = getToken();
  const body = {
    token,
    query,
  };
  try {
    if (DEBUG) {
      console.log(body);
    }
    const response = await fetch(LABIT_SERVER, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (DEBUG) {
      console.log(data);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
};

export let rememberPassword = async (email) => {
  try {
    if (DEBUG) {
      console.log(email);
    }
    const response = await fetch(LABIT_RECOVERY_PASSWORD_SERVER, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({ email }).toString(),
    });

    const responseText = await response.text(); // Leer el cuerpo como texto primero

    let data = null;
    try {
      data = JSON.parse(responseText); // Intentar parsear como JSON
    } catch (e) {
      console.error("Failed to parse response as JSON", e);
      console.error("Response Text:", responseText);
      return { status: 'error', message: 'Server response was not valid JSON' };
    }

    if (DEBUG) {
      console.log(data);
    }
    return data;
  } catch (e) {
    console.log(e);
    return { status: 'error', message: 'An error occurred during the request' };
  }
};



export let rpost = async (endpoint, body, responseType = "json") => {
  body.token = getToken();
  try {
    const endpointURL = LABIT_REST_SERVER + endpoint + ".php";
    if (DEBUG) {
      console.log(endpointURL);
      console.log(body);
    }
    const response = await fetch(endpointURL, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    let data = null;
    if (responseType === "json") {
      data = await response.json();
    } else {
      data = await response.text();
    }
    if (DEBUG) {
      console.log(data);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
};
