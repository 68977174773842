<script>
  import { onMount, onDestroy } from "svelte";
  import { getAllProjects } from "../../../js/projects";
  import { getUserId, getRole, getPlatform } from "../../../js/localInfo";
  import TasksBox from "./components/TasksBox.svelte";
  import Calendar from "./components/Calendar.svelte";
  import SmallInfoBox from "./components/SmallInfoBox.svelte";
  import AppsListBox from "./components/AppsListBox.svelte";
  import LicensesBox from "../../tools/screens/components/LicensesBox.svelte";
  import AllPeopleTasks from "./components/AllPeopleTasks.svelte";
  import WebBrowser from "../../../components/WebBrowser.svelte";
  import Loading from "../../../components/Loading.svelte";

  import CompleteResourcePlanner from "../../../components/CompleteResourcePlanner/CompleteResourcePlanner.svelte";
  import {
    getAllMyTasks,
    getMyPlanners,
    getTasksFromPlanner,
  } from "../../../components/dashboard/outlook/outlook.js";
  import { openURL } from "../../../js/openURL";
  import { openNewWindow } from "../../../js/openNewWindow";

  import { myPlanners, selectedProjectId } from "../../../js/stores";
  import NewProjectStaff from "./components/NewProjectStaff.svelte";

  const platform = getPlatform();
  const myId = getUserId();
  const myRole = getRole();

  let canEdit = false;
  let loadingProjects = false;
  let projectDropDownMenu = false;

  let project;
  let people;

  let allMyProjects = [];
  let showTool = "";
  let toolURL = "";

  let projectProgressTasks = {
    todo: [],
    inProgress: [],
    done: [],
  };

  let allMyProjectTasks = {
    todo: [],
    inProgress: [],
    done: [],
  };

  const newWindow = (ev, url) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (url !== undefined) openNewWindow(url);
  };

  const toggleProjectMenu = () => {
    projectDropDownMenu = !projectDropDownMenu;
  };

  const getAllMyProjects = async () => {
    let allMyProjects = [];
    let allProjects = await getAllProjects(true, myId);

    allProjects.forEach((project) => {
      if (project.active) {
        allMyProjects.push(project);
      }
    });
    return allMyProjects;
  };

  let generateAppList = (urls) => {
    let apps = [];
    urls.forEach((url) => {
      switch (url.type) {
        case "Server":
          apps.push({
            img: "/images/folder.png",
            link: url.url,
          });
          break;
        
        case "Autodesk Contruction Cloud":
          apps.push({
            img: "/images/autodesk.png",
            link: url.url,
          });
          break;

        case "Planner":
          apps.push({
            img: "/images/microsoft_planner.png",
            link: url.url,
          });
          break;

        case "Trello":
          apps.push({
            img: "/images/trello.png",
            link: url.url,
          });
          break;

        case "Slack":
          apps.push({
            img: "/images/slack.png",
            link: url.url,
          });
          break;

        case "Sharepoint":
          apps.push({
            img: "/images/sharepoint.png",
            link: url.url,
          });
          break;

        case "BIM360":
          apps.push({
            img: "/images/bim360.png",
            link: url.url,
          });
          break;

        default:
          break;
      }
    });

    return apps;
  };

  const userCanEdit = (project) => {
    if (myRole === "Admin" || myRole === "Analytics") {
      canEdit = true;
    } else {
      for (let i = 0; i < project.phases.length && !canEdit; i++) {
        const phase = project.phases[i];
        for (let j = 0; j < phase.people.length && !canEdit; j++) {
          const p = phase.people[j];
          if (p.peopleId === myId.toString() && p.role === "Project Manager") {
            canEdit = true;
          }
        }
      }
    }
  };

  const getScopes = (scopes) => {
    return scopes.reduce((acc, curr) => 
      acc.push({ name: curr, date: null }),
    []);
  };

  const getAllTasks = async () => {
    const projectName = project.folderLabitCode;

    let planners = [];
    if ($myPlanners.length === 0) {
      planners = await getMyPlanners();
    } else {
      planners = $myPlanners;
    }

    myPlanners.set(planners);

    const index = planners.findIndex(
      (e) => projectName.toLowerCase() === e.name.toLowerCase()
    );

    if (index > -1) {
      const id = planners[index].id;
      projectProgressTasks = await getTasksFromPlanner(
        planners[index].owner,
        id
      );

      const allMyTasks = await getAllMyTasks();
      allMyProjectTasks.todo = allMyTasks.todo.filter((e) => e.planId === id);
      allMyProjectTasks.inProgress = allMyTasks.inProgress.filter(
        (e) => e.planId === id
      );
      allMyProjectTasks.done = allMyTasks.done.filter((e) => e.planId === id);
    } else {
      projectProgressTasks = { todo: [], inProgress: [], done: [] };
      allMyProjectTasks = { todo: [], inProgress: [], done: [] };
    }
  };

  let closeTool = () => {
    showTool = "";
  };

  let clickTool = (url, forceNewWindow) => {
    toolURL = url;
    console.log(toolURL);
    if (platform === "Web") {
      openNewWindow(toolURL);
    } else {
      if (forceNewWindow) {
        openNewWindow(toolURL);
      } else {
        showTool = toolURL;
      }
    }
  };

  let getTasksInterval;
  onMount(async () => {
    getTasksInterval = setInterval(() => {
      const element = document.getElementById("PDashboard");
      if (element.offsetParent !== null) {
        if (project !== undefined) {
          getAllTasks();
        }
      }
    }, 15000);
  });

  onDestroy(() => {
    clearInterval(getTasksInterval);
  });

  const loadProjectSelected = (id) => {
    let index = allMyProjects.findIndex((item) => item.id === id);
    index = index === -1 ? 0 : index;
    let selectedProject = allMyProjects[index];
    people = selectedProject.people;
    userCanEdit(selectedProject);
    const projectApps = generateAppList(selectedProject.externalUrls);
    const projectScopes = getScopes(selectedProject.scopes);
    selectedProject.apps = projectApps;
    selectedProject.scopes = projectScopes;
    project = selectedProject;
    getAllTasks();
  }

  (async () => {
    loadingProjects = true;
    allMyProjects = await getAllMyProjects();
    loadProjectSelected($selectedProjectId);
    loadingProjects = false;
  })();

  $: {
    if (allMyProjects.length === 0) {
      (async () => { 
        allMyProjects = await getAllMyProjects();
        loadProjectSelected($selectedProjectId);
      })();
    } else {
      loadProjectSelected($selectedProjectId);
    }
  }

</script>

<!-- Menu -->
<div class="content">
  {#if project}
    <div
      class="projectSelectorContainer"
      on:click={() => {
        if (!loadingProjects) {
          toggleProjectMenu();
        }
      }}
    >
      <div
        class="projectImgContainer"
        style="background-image: url({project.pic});"
      />
      <div class="projectNameContainer">
        <span>{project.name}</span>
        {#if loadingProjects}<div class="loader">
            <Loading size="50px" />
          </div>{/if}
      </div>
      <div class="downArrow">
        <img src="images/down_arrow.svg" alt="" />
      </div>
    </div>
    <div
      class="projectListContainer {projectDropDownMenu ? '' : 'hiddenElement'}"
    >
      {#each allMyProjects as project}
        <div
          class={project.active ? "selectableProject" : "noActiveProject"}
          on:click={() => {
            //loadProjectSelected(project.id);
            selectedProjectId.set(project.id);
            localStorage.setItem('selectedProjectId', project.id);
            toggleProjectMenu();
            closeTool();
          }}
        >
          <div
            class="projectImgContainer"
            style="background-image: url({project.pic}); "
          />
          <div class="projectNameContainer">
            <span>{project.name}</span>
          </div>
        </div>
      {/each}
    </div>
  {/if}
  <div class="boxContainer">
    {#if project}
      <div class="firstCol">
        <div class="largeBox">
          <div
            class="projectImg"
            style="background-image: url({project.pic});"
          />
          <div class="projectInfo">
            <div class="skylabTitle">
              <span>{project.name}</span>
            </div>
            <div class="projectData">
              <span>City: {project.city}</span>
              <br />
            </div>
          </div>
        </div>
        <div class="projectInfo">
          <div
            class="dashboardItemLarge {canEdit ? 'dashboardItemLargeHalf' : ''}"
          >
            <SmallInfoBox text={project.currentStatus.toUpperCase()} />
          </div>
          {#if canEdit}
            <div class="dashboardItemLarge dashboardItemLargeHalf">
              <div
                class="editProject fadedButton"
                on:click={() =>
                  openURL("/project-analytics/pm/" + project.id, true)}
                on:contextmenu={(ev) => {
                  ev.stopPropagation();
                  openNewWindow(
                    "/skylab-main/project-analytics/pm/" + project.id
                  );
                  return false;
                }}
              >
                <img src="/images/edit.svg" alt="Edit Project" />
                <span>EDIT</span>
              </div>
            </div>
          {/if}
        </div>
      </div>
      <div class="infoCardBox">
        <div class="dashboardItem">
          <Calendar dashboard={true} {project} />
        </div>
        <div class="dashboardItem">
          <TasksBox
            editable = {true}
            boxTitle={"My Project Tasks"}
            taskList={{ todo: allMyProjectTasks.todo, progress: allMyProjectTasks.inProgress }}
            on:updated={async () => await getAllTasks()}
          />
        </div>
        <div class="dashboardItem">
          <TasksBox
            editable={false}
            boxTitle={"Project Tasks"}
            taskList={{ todo: projectProgressTasks.todo, progress: projectProgressTasks.inProgress}}
            on:updated={async () => await getAllTasks()}
          />
        </div>
        <div class="dashboardItem">
          <AppsListBox
            boxTitle="Apps"
            apps={project.apps}
            onClick={(url, forceNewWindow) => clickTool(url, forceNewWindow)}
          />
        </div>

        <div class="dashboardItem">
          <LicensesBox
          boxTitle="Licenses" 
          projectId={project.id} />
        </div>
        <!-- <div class="dashboardItem">
          <ListBox boxTitle={"Scope"} list={project.scopes} />
        </div> -->
        <!-- <div class="dashboardItem">
          <ListBox boxTitle={"Site Monitoring"} list={siteMonitoring} />
        </div> -->
        <!--         <div class="dashboardItem">
          <RoomDataSheet
            boxTitle={"Room data sheet"}
            projectId={project.id}
          />
        </div> -->
        <div class="dashboardItem">
          {#key people}
          <NewProjectStaff {people} />
          {/key}
        </div>
        <div
          class="dashboardItem doubleWidget"
          on:click={() => clickTool(projectProgressTasks.link, false)}
          on:contextmenu={(ev) => {
            newWindow(ev, projectProgressTasks.link);
          }}
        >
          <AllPeopleTasks
            id="peopleTasksChart"
            boxTitle={"Project Progress"}
            nTodo={projectProgressTasks.todo.length}
            nInProgress={projectProgressTasks.inProgress.length}
            nDone={projectProgressTasks.done.length}
          />
        </div>
        <div
          class="dashboardItem doubleWidget"
          on:click={() => clickTool(projectProgressTasks.link, false)}
          on:contextmenu={(ev) => {
            newWindow(ev, projectProgressTasks.link);
          }}
        >
          <AllPeopleTasks
            id="myTasksChart"
            boxTitle={"My Progress"}
            nTodo={allMyProjectTasks.todo.length}
            nInProgress={allMyProjectTasks.inProgress.length}
            nDone={allMyProjectTasks.done.length}
          />
        </div>
      </div>
    {/if}
  </div>
  <div class="tool {(showTool !== '' && showTool !== undefined) ? '' : 'hiddenElement'}">
    <div class="toolOptions">
      <img
        class="fadedButton"
        src="/images/x.svg"
        alt="Close Tool"
        on:click={() => closeTool()}
      />
    </div>
    <div class="toolWebview">
      <WebBrowser
        id="pdToolWebview"
        type="webview"
        iframeTitle="External Tool"
        src={showTool}
      />
    </div>
  </div>
  <!--<div class="planner">
    <Planner project={project} plan={currentPlan} />
  </div>-->
  {#if project}
    <div class="planner">
      <CompleteResourcePlanner projectId={project.id} editable={false} />
    </div>
  {/if}
</div>

<!--
{#if platform === "APP" && showTool !== ""}
  <ContextMenu currentURL={toolURL} bind:showMenu={showNavMenu} />
{/if}
-->

<!-- Menu -->
<style>
  div.boxContainer {
    width: 1640px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
  }

  div.largeBox {
    width: 260px;
    height: 450px;
    background-color: var(--labit-white);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    /*margin-bottom: 20px;*/
  }

  div.projectImg {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  div.skylabTitle {
    padding: 8px;
    color: var(--labit-card-gray);
  }

  div.projectData {
    padding-left: 8px;
    padding-right: 8px;
    color: var(--labit-card-gray);
    /*     margin-bottom: 12px; */
  }

  div.skylabTitle span {
    font-size: 25px;
    font-weight: bold;
  }

  div.dashboardItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 300px;
    background-color: white;
    border-radius: 14px;
    margin-bottom: 20px;
  }

  div.dashboardItemLarge {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 260px;
    border-radius: 14px;
    height: 100%;
    padding-top: 20px;
  }

  div.dashboardItemLargeHalf {
    height: 50% !important;
  }

  div.infoCardBox {
    width: 83%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div.planner {
    width: 100%;
    margin-bottom: 20px;
    height: 800px;
    display: flex;
    margin-top: 20px;
  }

  div.projectSelectorContainer {
    width: 100%;
    height: 60px;
    border-radius: 14px;
    margin-top: 20px;
    background-color: var(--labit-white);
    display: flex;
    align-items: center;
    padding: 0 40px 0 40px;
    cursor: pointer;
    color: var(--labit-card-gray);
  }

  div.downArrow {
    width: 20px;
    height: 20px;
  }

  div.projectImgContainer {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  div.projectNameContainer {
    margin-left: 20px;
    width: 95%;
    display: flex;
    align-items: center;
  }

  div.projectNameContainer span {
    font-size: 22px;
    font-weight: bold;
  }

  div.projectListContainer {
    margin-top: 20px;
    background-color: var(--labit-white);
    display: flex;
    flex-direction: column;
    border-radius: 14px;
  }

  div.selectableProject,
  div.noActiveProject {
    padding-left: 40px;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }

  div.selectableProject:hover {
    opacity: 0.5;
  }

  div.noActiveProject {
    filter: grayscale();
    opacity: 0.5;
  }

  div.loader {
    display: flex;
    justify-content: center;
    margin-left: 20px;
  }

  div.projectInfo {
    height: 170px;
    overflow-wrap: break-word;
  }

  div.editProject {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--labit-card-gray);
    font-size: 18px;
    font-weight: bold;
    border-radius: 14px;
  }

  div.editProject img {
    width: 18px;
    margin-right: 6px;
  }

  div.doubleWidget {
    width: 535px !important;
  }

  div.tool {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.toolOptions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  div.toolOptions img {
    width: 10px;
  }

  div.toolWebview {
    width: 100%;
    height: 800px;
  }
</style>
