<script>
  import { post } from '../../../../js/labit-connection';   
  import Toggle from "../../../../components/ToggleSwitchSelector.svelte";
  import Picture from "../../../../components/UserPicture.svelte";
  import { openURL } from "../../../../js/openURL";

  import dayjs from 'dayjs';
  import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
  import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
  export let people;

  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore); 

  const options = {
    1: { id: 1, value: 'Project Staff', active: false },
    2: { id: 2, value: 'Absences', active: false }
  }

	let selected = options["2"].id;

  let users = {};
  let absences = [];

  const today = dayjs();
  const nextAbsencesLimit = dayjs().add(1, 'week');

  const size = 40;

  (async () => {
    const absencesRes = await post('SELECT a.start, a.end, a.accepted_by_ceo, a.half, a.user_id, at.approvable FROM absences as a JOIN absence_type as at ON a.absence_type_id = at.id;');

    users = people
      .filter((user, i, arr) => user.active === '1' && arr.findIndex(t => t.peopleId === user.peopleId) === i)
      .map(user => {
        const [id, pic, name, role] = [user.peopleId, user.peoplePic, user.peopleName, user.role];
        return {id, pic, name, role};
      });

      const absencesTransformed = absencesRes
      .map(absence => {
        const { start, end, accepted_by_ceo, half, user_id, approvable, type } = absence;
        const index = users.findIndex(user => user.id === absence.user_id);
        const pic = index === -1 ? '' : users[index].pic;
      
        // Asegúrate de convertir a dayjs
        return {
          id: user_id,
          type,
          start: dayjs(parseInt(start)), // Conversión correcta a dayjs
          end: dayjs(parseInt(end)),     // Conversión correcta a dayjs
          accepted: parseInt(accepted_by_ceo),
          half: parseInt(half),
          pic,
          approvable: parseInt(approvable)
        };
      })
      .filter(abs => abs.end.isSameOrAfter(today) && (abs.accepted === 1 || abs.approvable === 0))
      .reduce((acc, absence) => {
        if (!acc.hasOwnProperty(absence.id)) {
          acc[absence.id] = [absence];
        } else {
          let merged = false;
          acc[absence.id] = acc[absence.id].map(existingAbsence => {
            // Verificar si las ausencias se solapan o están adyacentes
            if (
              absence.start.isSameOrBefore(existingAbsence.end.add(1, "day")) &&
              absence.end.isSameOrAfter(existingAbsence.start.subtract(1, "day"))
            ) {
              // Fusionar las ausencias
              existingAbsence.start = dayjs.min(existingAbsence.start, absence.start);
              existingAbsence.end = dayjs.max(existingAbsence.end, absence.end);
              merged = true;
              return existingAbsence;
            }
            return existingAbsence;
          });
          // Si no se fusionó, agregar como una nueva entrada
          if (!merged) {
            acc[absence.id].push(absence);
          }
        }
        return acc;
      }, {});

      absences = Object.values(absencesTransformed).flat().filter(abs => abs.start.isBefore(nextAbsencesLimit,'day') && users.findIndex(user => user.id === abs.id) !== -1);
      
      options["2"].active = absences.length > 0;
    })();

</script>

<div class="main flex">
  <div class="header">
      <Toggle {options} bind:selected title={true} />
  </div>
  <div class="content">
      {#if selected === 1}
        {#each users as {id, pic, name, role}}
        <div class="userPic" on:click={ () => { openURL(`/skylab-main/people/show-people/${id}`) } }>
          <Picture src={pic} {size} 
          tooltipText={`${name} - ${role}`}
          active={false}
          />
        </div>
        {/each}
      {:else}
          {#each absences as {start, end, half, pic}, i (i)}
          {@const tooltipText = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`}
          {@const active = today.isBetween(start, end, 'day', '[]')}
              <Picture src={pic} {size} {tooltipText} {active} />
          {/each}
      {/if}
  </div>
</div>

<style>
  .main {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
  }

  .flex {
      display: flex;
  }

  .header, .content {
      max-width: 100%;
  }

  .content {
      padding: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow-y: auto;
      gap: 4px;
  }

  .header {
      min-height: 45px;
      height: 45px;
      border-bottom: 1px solid #e3e3e3;
  }

  .content::-webkit-scrollbar {
      width: 4px;
  }

  .content::-webkit-scrollbar-track {
      background: #efefef;
  }

  .content::-webkit-scrollbar-thumb {
      background-color: #cfcfcf;
      border-radius: 20px;
  }
  div.userPic {
    width: 40px;
    height: 40px;
    margin: 8px;
  }
</style>

